import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SessionParameterType } from '../../enums/generated.enums';
import { SchedulerActionParameter, SchedulerActionParameterValue } from '../../types/scheduler/scheduler-task.type';
import { FormHelper } from '../../helpers/form.helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SchedulerParametersHelper } from '../../helpers/scheduler-parameters.helper';
import { MapHelper } from '../../helpers/map.helper';

@Component({
  selector: 'shared-parameters-editor',
  templateUrl: './parameters-editor.component.html',
  styleUrls: ['./parameters-editor.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ParametersEditorComponent }],
})
export class ParametersEditorComponent implements ControlValueAccessor, OnInit {
  @Input() parametersDefinition: SchedulerActionParameter[];

  form: FormArray<FormGroup<ParameterFormControls>> = this.formBuilder.array([] as FormGroup<ParameterFormControls>[]);

  private onChange = (value: SchedulerActionParameterValue[]) => {};
  private onTouched = () => {};
  private touched = false;
  private isInitialized = false;
  isDisabled: boolean;
  private parameterTypes: Map<string, string>;

  get f() {
    return this.form.controls;
  }

  get formGroup(): any {
    return this.form;
  }

  constructor(private formBuilder: FormBuilder) {
    this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => this.notifyChanged());
  }

  ngOnInit(): void {
    this.parameterTypes = this.parametersDefinition.reduce((m,p) => m.set(p.name, p.type), new Map<string,string>());
  }

  writeValue(value: SchedulerActionParameterValue[]): void {
    this.isInitialized = false;
    value = SchedulerParametersHelper.combineParameters(this.parametersDefinition, value);
    this.form.clear();
    value.forEach(p => this.form.push(this.createParameter(p)));
    this.isInitialized = true;
  }

  private createParameter(parameterValue: SchedulerActionParameterValue): FormGroup {
    return this.formBuilder.group({
      name: this.formBuilder.control(parameterValue.name),
      value: this.formBuilder.control(parameterValue?.value ?? ''),
    });
  }

  registerOnChange(onChange: (value: SchedulerActionParameterValue[]) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    FormHelper.setEnabled(this.form, !isDisabled);
  }

  private notifyChanged() {
    if (!this.isInitialized){
      return;
    }
    this.markAsTouched();
    this.onChange(SchedulerParametersHelper.removeEmptyValues(<SchedulerActionParameterValue[]>this.form.value));
  }

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  getParameterType(name: string){
    return this.parameterTypes.get(name);
  }
}

interface ParameterFormControls {
  name: FormControl<string>;
  value: FormControl<string>;
}
