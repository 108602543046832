@if (data.labels?.length > 1) {
  <div class="graph-legend">
    @for (label of data.labels; track label; let i = $index) {
      <div class="legend-item">
        <span class="pointee-colored-circle" [style.background-color]="datasetColors[i]"></span>
        <span class="legend-text">{{ label }} </span>
      </div>
    }
  </div>
}

<div class="chart-js" [style.height]="height">
  <canvas #barChart></canvas>
</div>
