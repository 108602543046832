import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseModalDialogDirective } from '../../../directives/base-modal-dialog.directive';
import { FormatType } from '../../../enums/format-type.enum';
import { IntervalType } from '../../../enums/generated.enums';
import { DateHelper, DateRangeFormat } from '../../../helpers/date.helper';
import { ApiService } from '../../../services/api/api.service';
import { EstimationService } from '../../../services/estimation.service';
import { LoadingScreenService } from '../../../services/loading-screen.service';
import { LoggingService } from '../../../services/logging.service';
import { SelectionFacadeService } from '../../../services/selection-facade.service';
import { Data } from '../../../types/data.type';
import { ProcessStatistics } from '../../../types/statistics/process-statistics.type';

@Component({
  selector: 'app-estimation-modal',
  templateUrl: './estimation-modal.component.html',
  styleUrls: ['./estimation-modal.component.scss'],
})
export class EstimationModalComponent extends BaseModalDialogDirective {
  readonly FormatType = FormatType;
  readonly IntervalType = IntervalType;

  submitted = false;
  statistics: ProcessStatistics;
  formattedProcessInterval: string;
  form = this.formBuilder.group({
    estimation: [this.data.masterProcessUserData.enteredIntervalCasesCount, [Validators.required, Validators.min(1), Validators.max(2000000000)]],
    estimationPeriod: [this.data.masterProcessUserData.intervalTypeForCasesCounts ?? IntervalType.Month, [Validators.required]],
  });

  get f() {
    return this.form.controls;
  }

  get estimationChanged(): boolean {
    return this.estimationService.estimationChanged;
  }

  get taskTypeLabel(): string {
    return this.estimationService.taskTypeLabel;
  }

  constructor(
    private formBuilder: FormBuilder,
    private data: Data,
    private selectionFacadeService: SelectionFacadeService,
    private estimationService: EstimationService,
    private apiService: ApiService,
    private loadingScreenService: LoadingScreenService,
    private loggingService: LoggingService,
  ) {
    super();

    const { masterProcess } = this.data;
    this.statistics = masterProcess.hasItems ? masterProcess.itemsProcessStatistics : masterProcess.sessionsProcessStatistics;
    this.formattedProcessInterval = DateHelper.formatDateRange(this.data.process.from, this.data.process.to, DateRangeFormat.DateOnly);
  }

  async updateEstimation(): Promise<void> {
    if (this.submitted || this.form.invalid) {
      return;
    }
    this.submitted = true;
    const intervalType = this.f.estimationPeriod.value as IntervalType;
    const calculated = intervalType === IntervalType.Month ? this.statistics.monthCasesCount : this.statistics.yearCasesCount;
    await this.update(this.f.estimation.value, intervalType, calculated);
  }

  async resetEstimation(): Promise<void> {
    if (this.submitted) {
      return;
    }
    this.submitted = true;
    await this.update(null, null, null);
  }

  private async update(enteredIntervalCasesCount: number, intervalTypeForCasesCounts: IntervalType, calculatedIntervalCasesCount: number) {
    const { masterProcessUserData } = this.data;
    if (!masterProcessUserData) {
      return;
    }
    this.close();

    // simulate some processing :-)
    await this.loadingScreenService.showFakeLoading(1000);

    masterProcessUserData.enteredIntervalCasesCount = enteredIntervalCasesCount;
    masterProcessUserData.intervalTypeForCasesCounts = intervalTypeForCasesCounts;
    masterProcessUserData.calculatedIntervalCasesCount = calculatedIntervalCasesCount;

    this.estimationService.initialize();
    try {
      await this.apiService.updateEstimationInterval(this.selectionFacadeService.selectedMasterProcess.masterProcessId, masterProcessUserData);
    } catch (error: any) {
      this.loggingService.logException(error);
    }
  }
}
