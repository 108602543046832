import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Uid } from '../../helpers/uid.type';

@Component({
  selector: 'shared-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() isDisabled = false;
  @Input() isAnimated = false;
  @Input() mode: 'switch' | 'toggle' = 'switch';
  @Input() activeColor: 'primary' | 'secondary' = 'primary';
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  readonly id = `switch-${Uid.generate()}`;

  get switchClasses(): { [key: string]: boolean } {
    return {
      disabled: this.isDisabled,
      animated: this.isAnimated,
      switch: this.mode === 'switch',
      'secondary-color': this.activeColor === 'secondary',
    };
  }

  constructor(private cdr: ChangeDetectorRef) {}

  onChange: any = () => {
    this.valueChange.emit(this.value);
  };

  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  toggleSwitch(): void {
    this.value = !this.value;
    this.onChange(this.value);
    this.onTouched();
  }
}
