<shared-modal [modalTitle]="'New analysis'">
  <ng-container modalBody>
    @if (platforms?.length > 0) {
      <div class="section">
        <p class="m-b-8"><strong>Uploading a log exported from:</strong></p>
        <mat-button-toggle-group [(value)]="selectedPlatform" aria-label="Platform" hideSingleSelectionIndicator="true">
          @for (platform of platforms; track platform) {
            <mat-button-toggle [value]="platform.value">{{ platform.label }}</mat-button-toggle>
          }
        </mat-button-toggle-group>
      </div>
    }

    <div class="section upload-info-box">
      <ul class="bullets">
        <li>Export a process log from {{ platform.label }} in <span [innerHtml]="platform.logFileTypesHtml"></span> format and upload it here.</li>
        @if (platform.isUiPath) {
          <li>If you use verbose logging, Pointee will provide you with even more detailed data and deeper insights.</li>
        } @else {
          <li>To increase the speed of upload you may compress the <span [innerHtml]="platform.logFileTypesHtml"></span> file into a <b>*.zip</b> file first.</li>
        }
        @if (platform.isUiPath) {
          <li>If you’re uploading logs from multiple days, compress the files into one <b>*.zip</b> file first.</li>
        }
        <li>You can upload multiple processes at once.</li>
      </ul>
    </div>
    <app-file-drop-box
      (fileDrop)="onFileDrop($event)"
      [allowMultipleFiles]="true"
      [acceptedFileTypes]="platform.acceptedFileTypes"
      [acceptedFileTypesText]="platform.logFileTypesText"
    ></app-file-drop-box>
  </ng-container>
</shared-modal>
