import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise } from 'rxjs/operators';

export enum BorderStyle {
  Flat,
  Bevel,
  Ghost
}

@Component({
  selector: 'shared-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements AfterViewInit {
  @Input() searchValue = '';
  @Input() placeholder: string;
  @Input() closeAlwaysVisible = false;
  @Input() debounceTime = 300;
  @Input() minTextLength = 1;
  @Input() border = BorderStyle.Flat;
  @Output() search = new EventEmitter<string>();
  @Output() clear = new EventEmitter<string>();

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @HostListener('document:keydown.escape', ['$event'])onKeydownHandler(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.clearSearch(event);
    }
  }

  readonly BorderStyle = BorderStyle;
  private searchValueSubject$ = new Subject<string>();

  constructor(private destroyRef: DestroyRef) {}

  ngAfterViewInit(): void {
    const inputValue$ = fromEvent<Event>(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(this.debounceTime),
      map(event => (event.target as HTMLInputElement).value)
    );

    merge(inputValue$, this.searchValueSubject$)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        pairwise(),
        map(([prev, curr]) => {
          const normPrev = prev?.length < this.minTextLength ? '' : prev;
          const normCurr = curr?.length < this.minTextLength ? '' : curr;
          return [normPrev, normCurr];
        }),
        filter(([prev, curr]) => prev !== curr),
        map(([_, curr]) => curr)
      )
      .subscribe(value => this.search.emit(value));

    this.searchValueSubject$.next(this.searchValue);
  }

  clearSearch(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.searchValue = '';
    this.searchValueSubject$.next(this.searchValue);
    this.clear.emit();
  }
}
