<shared-modal [modalTitle]="'Tell us what\'s working and what\'s not'" [showFooter]="true">
  <div class="md-float-material form-material" modalBody>
    <p>Do you have a question? Is something not working? Is there something that would make Pointee more useful to you? Please let us know!</p>
    @if (!isOnPremise) {
      @if (!submitted) {
        <p><b>What would you like to share with us?</b></p>
        <form [formGroup]="contactCareForm" (ngSubmit)="onSubmit()">
          <div class="form-group message-area">
            <textarea #messageInput formControlName="message" class="form-control" rows="3" autofocus></textarea>
            <mat-checkbox formControlName="attachFiles" [displayRipple]="false">Attach files</mat-checkbox>
            @if (contactCareForm.controls.attachFiles.value) {
              <div class="upload-items-body">
                @for (item of attachmentFiles; track item) {
                  <div class="upload-item" [ngClass]="{ success: item.isSuccessful, error: item.isError }">
                    <ng-container>
                      <app-processing-progress-circle [status]="item.isUploaded ? ProcessingItemStatus.Successful : item.status" [totalProgress]="item.totalProgress"> </app-processing-progress-circle>
                      <div class="upload-info">
                        <div class="upload-file" [tooltip]="item.fileName" [zIndex]="10000" [maxWidth]="'100%'" tooltipIfEllipsis>
                          <span>{{ item.fileName }}</span>
                          @if (item.isUploading) {
                            <span> ({{ item.operationProgress | format: FormatType.Percentage }})</span>
                          }
                        </div>
                      </div>
                      @if (item.canCancel) {
                        <button type="button" class="btn btn-link processing-cancel-button" (click)="cancelUpload(item)">Cancel</button>
                      }
                      <button type="button" class="btn btn-delete" (click)="deleteFile(item)">
                        @if (item.isUploaded || item.isError) {
                          <svg-icon key="delete-24" size="sm" class="delete-icon"></svg-icon>
                        }
                      </button>
                    </ng-container>
                  </div>
                }
              </div>
              <app-file-drop-box (fileDrop)="onFileDrop($event)" [allowMultipleFiles]="true"></app-file-drop-box>
            }
          </div>
          <button type="submit" class="btn btn-primary" [ngClass]="{ disabled: isFormInvalid }" [disabled]="isFormInvalid">Submit</button>
        </form>
      }
    }
    @if (isOnPremise) {
      <div class="m-t-8 m-b-24">
        <a href="mailto:support@pointee.com">support&#64;pointee.com</a>
      </div>
    }

    @if (submitted) {
      <div class="row confirm-box">
        <div class="col-12 text-center">
          <svg-icon key="send-email-fly" class="send-icon" fontSize="56px"></svg-icon>
        </div>
        <div class="col-12 text-center">
          <p>Thank you!<br />We'll get back to you as soon as possible.</p>
        </div>
      </div>
    }
  </div>
  <div class="flex-row flex-between flex-center" modalFooter>
    <div class="m-y-8 color-gray"><b class="color-gray">Pointee version:</b> {{ appVersion }}</div>
    @if (isDocumentationEnabled) {
      <a target="_blank" [href]="documentationUrl">Documentation</a>
    }
  </div>
</shared-modal>
