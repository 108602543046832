import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { JumpToModalComponent } from 'src/app/shared/components/_modals/jump-to-modal/jump-to-modal.component';
import { ParamMapHelper } from 'src/app/shared/helpers/param-map.helper';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { BreadcrumbItem } from '../../shared/types/breadcrumb-item.type';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  queryParams: Params;
  ellipsisTooltipOptions = {
    display: false,
    maxWidth: '500px',
  };

  get displaySubprocessPath(): boolean {
    return this.breadcrumbService.displaySubprocessPath;
  }

  get breadcrumbItems(): BreadcrumbItem[] {
    return this.breadcrumbService.breadcrumbItems;
  }

  constructor(private activatedRoute: ActivatedRoute, private breadcrumbService: BreadcrumbService, private modalService: ModalService) {
    this.activatedRoute.queryParamMap.subscribe(async paramMap => {
      if (ParamMapHelper.containsIntervalParams(paramMap)) {
        this.queryParams = ParamMapHelper.convertToParams(paramMap);
      }
    });
  }

  onMouseOver(event: MouseEvent): void {
    const target = event.target as HTMLSpanElement;
    this.ellipsisTooltipOptions = {
      ...this.ellipsisTooltipOptions,
      display: target.textContent === '...' || (target.clientWidth > 0 && target.scrollWidth > target.clientWidth),
    };
  }

  openJumpToPanel(): void {
    this.modalService.open(JumpToModalComponent, d => d);
  }

  shouldDisplayItem(index: number) {
    return index === 0 || this.displaySubprocessPath;
  }

  shouldDisplayArrow(index: number) {
    return index < this.breadcrumbItems.length - 1 && this.displaySubprocessPath;
  }
}
