import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { TextHelper } from '../helpers/text.helper';

@Directive({ selector: '[shared-text-ellipsis]' })
export class TextEllipsisDirective implements OnChanges {
  @Input() text: string;
  @Input() length = 64;
  @Input() shouldEllipsis = true;
  @Input() readMore = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.ellipsisElementText();
  }

  private ellipsisElementText(): void {
    if (!this.shouldEllipsis || this.text.length <= this.length) {
      (this.el.nativeElement as HTMLElement).innerText = this.text;
    } else {
      (this.el.nativeElement as HTMLElement).innerText = TextHelper.textEllipsis(this.text, this.length);

      // add READ MORE link
      if (this.readMore) {
        const element = this.renderer.createElement('span');
        const text = this.renderer.createText('Read more');
        this.renderer.appendChild(element, text);
        this.renderer.addClass(element, 'text-link');
        this.renderer.addClass(element, 'simple');
        this.renderer.listen(element, 'click', (event: any) => {
          this.shouldEllipsis = false;
          this.ellipsisElementText();
        });
        this.renderer.appendChild(this.el.nativeElement, element);
      }
    }
  }
}
