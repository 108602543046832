@if (breadcrumbItems?.length > 0) {
  <div class="breadcrumb-container">
    @for (breadcrumb of breadcrumbItems; track breadcrumb; let i = $index) {
      @if (shouldDisplayItem(i)) {
        @if (breadcrumb.route) {
          <a [routerLink]="breadcrumb.route" id="breadcrumbLevel{{ i }}" class="breadcrumb-item">
            <span [tooltip]="breadcrumb.tooltip" [options]="ellipsisTooltipOptions" (mouseover)="onMouseOver($event)" class="item-text">{{ breadcrumb.label }}</span>
          </a>
        } @else {
          <div class="breadcrumb-item">
            <span [tooltip]="breadcrumb.tooltip" [options]="ellipsisTooltipOptions" (mouseover)="onMouseOver($event)" class="item-text">{{ breadcrumb.label }}</span>
          </div>
        }
        @if (shouldDisplayArrow(i)) {
          <svg-icon key="arrow-button-right" size="xs" class="arrow-icon"></svg-icon>
        }
      }
    }
  </div>
}

@if (displaySubprocessPath) {
  <button id="jumpToButton" class="btn-ghost-primitive jump-to-button" [tooltip]="'Jump to ...'" (click)="openJumpToPanel()" aria-label="jump to button">
    <svg-icon key="jump-to" size="lg"></svg-icon>
  </button>
}
