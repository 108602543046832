import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { HotkeysHelpModalComponent } from '../components/_modals/hotkeys-help-modal/hotkeys-help-modal.component';
import { JumpToModalComponent } from '../components/_modals/jump-to-modal/jump-to-modal.component';
import { PageType, SubPageType } from '../enums/page-type.enum';
import { ModalService } from './modal.service';
import { NavigationService } from './navigation.service';

interface Options {
  element: any;
  keys: string;
}

@Injectable({ providedIn: 'root' })
export class HotKeysService {
  defaults: Partial<Options> = {
    element: this.document,
  };

  constructor(private eventManager: EventManager, @Inject(DOCUMENT) private document: Document, private modalService: ModalService, private navigationService: NavigationService) {
    this.addShortcut({ keys: 'shift.?' }).subscribe(() => {
      if (this.navigationService.isOnSubPage(SubPageType.ProcessMap) || this.navigationService.isOnSubPage(SubPageType.VariantAnalysis)) {
        this.modalService.open(HotkeysHelpModalComponent);
      }
    });

    this.addShortcut({ keys: 'control.x' }).subscribe(() => {
      if (this.navigationService.isOnSubPage(SubPageType.ProcessMap) || this.navigationService.isOnSubPage(SubPageType.VariantAnalysis)) {
        this.modalService.open(JumpToModalComponent);
      }
    });
  }

  addShortcut(options: Partial<Options>): Observable<any> {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;
    return new Observable(observer => {
      const handler = (e: Event) => {
        if (this.modalService.isSomeOpen()) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(merged.element, event, handler);

      return () => {
        dispose();
      };
    });
  }
}
