<mat-button-toggle-group class="m-b-32" [value]="selectedExceptionType" (change)="onExceptionTypeChanged($event)" hideSingleSelectionIndicator="true">
  <mat-button-toggle [value]="AllExceptions">All exceptions</mat-button-toggle>
  <mat-button-toggle [value]="ExceptionType.SystemException">System exceptions</mat-button-toggle>
  <mat-button-toggle [value]="ExceptionType.BusinessException">Business exceptions</mat-button-toggle>
</mat-button-toggle-group>

<div class="exception-table-container">
  @if (isLoading) {
    <shared-loading-indicator></shared-loading-indicator>
  }
  <table class="fixed" mat-table matSort matSortDisableClear [matSortActive]="'Cost'" [matSortDirection]="'desc'" [dataSource]="exceptionsToDisplay" (matSortChange)="onSortChanged($event)">
    <ng-container matColumnDef="Text">
      <th mat-header-cell *matHeaderCellDef class="w-col-3">Exception</th>
      <td mat-cell *matCellDef="let exception" class="w-col-3 overflow-ellipsis" [tooltip]="getExceptionSourcesTooltip(exception)" [maxWidth]="'500px'">
        <span [class.unnamed-exception]="!exception.text">{{ exception.text ? exception.text : 'An unnamed exception' }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef class="w-col-3">Type</th>
      <td mat-cell *matCellDef="let exception" class="w-col-3">
        @if (exception.type === ExceptionType.BusinessException) {
          <div class="exception-type">
            <span class="pointee-colored-circle warning"></span>
            Business
          </div>
        } @else if (exception.type === ExceptionType.SystemException) {
          <div class="exception-type">
            <span class="pointee-colored-circle danger"></span>
            System
          </div>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="Count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" class="w-col-3">Count</th>
      <td mat-cell *matCellDef="let exception" class="w-col-3">{{ exception.count }}</td>
    </ng-container>
    <ng-container matColumnDef="Cost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" class="w-col-3">Cost ({{ timePeriodLabel }})</th>
      <td mat-cell *matCellDef="let exception" class="w-col-3">
        <span [tooltip]="getExceptionCostTooltip(exception)">{{ exception.cost | format: FormatType.Currency }}</span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: tableColumns"></tr>
  </table>
  @if (displayViewMoreButton) {
    <div class="table-view-more">
      <button class="btn btn-link" (click)="onViewMoreClicked()">View more<svg-icon class="left" key="arrow-button-down" size="xs"></svg-icon></button>
    </div>
  }
</div>

@if (exceptions.length === 0 && !isLoading) {
  <p class="p-t-12">No exceptions found</p>
}
