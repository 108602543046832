import { ActionableInsightStatus, IntervalType } from '../enums/generated.enums';
import { UserActionableInsight } from './actionable-insight.type';

export class MasterProcessUserData {
  calculatedIntervalCasesCount?: number;
  enteredIntervalCasesCount?: number;
  intervalTypeForCasesCounts?: IntervalType;
  manualProcessingCosts?: number;
  actionableInsightsUserData: Record<string, ActionableInsightUserData>;
  userActionableInsights: UserActionableInsight[];
}

export class ActionableInsightUserData {
  states: ActionableInsightStatus[];
}
