import { Injectable } from '@angular/core';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { Notification } from '../../types/notification.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/notifications`;
  }

  async getNotifications(): Promise<Notification[]> {
    const result = await this.get<Notification[]>('');
    result.forEach(i => ApiConversionHelper.convertNotification(i));
    return result;
  }

  async setNotificationIsRead(id: string): Promise<void> {
    return await this.patch(`${id}/is-read`, null);
  }

  async setAllNotificationsRead(): Promise<void> {
    return await this.patch('all-is-read', null);
  }
}
