import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, startWith } from 'rxjs';
import { AlertSeverity } from '../enums/alert-severity.enum';
import { NotificationType, Permission } from '../enums/generated.enums';
import { SubPageType } from '../enums/page-type.enum';
import { DateHelper, DateTimeUnit } from '../helpers/date.helper';
import { NotificationBarMessage, NotificationBarMessageType } from '../interfaces/notification-bar-message.interface';
import { Notification } from '../types/notification.type';
import { SchedulerStatus } from '../types/scheduler/scheduler-status.type';
import { ApiService } from './api/api.service';
import { AppConfigurationService } from './app-configuration.service';
import { AuthService } from './auth.service';
import { EventBusService, EventType } from './event-bus.service';
import { NavigationService } from './navigation.service';
import { OrganizationService } from './organization.service';
import { PlatformTextsService } from './platform-texts.service';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private notificationBarMessagesSubject = new BehaviorSubject<NotificationBarMessage[]>([]);
  private notificationsSubject = new BehaviorSubject<Notification[]>([]);

  get notificationBarMessages$(): Observable<NotificationBarMessage[]> {
    return this.notificationBarMessagesSubject.asObservable();
  }

  get notifications$(): Observable<Notification[]> {
    return this.notificationsSubject.asObservable();
  }

  get unreadNotificationsCount$(): Observable<number> {
    return this.notificationsSubject.asObservable().pipe(
      map(notifications => notifications.filter(n => n.isRead === false).length),
      startWith(0)
    );
  }

  get areNotificationEnabled(): boolean {
    return this.appConfigurationService.isOnPremise || this.organizationService.isDemoOrganization;
  }

  readonly licenseCountMessage = `<b>There are no ${this.platformTextsService.get('Blue Prism')} licenses assigned to Pointee orchestration.</b> Pointee cannot start processes.`;

  constructor(
    private apiService: ApiService,
    private appConfigurationService: AppConfigurationService,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private navigationService: NavigationService,
    private platformTextsService: PlatformTextsService,
    eventBusService: EventBusService
  ) {
    void this.refreshNotifications();
    setInterval(() => {
      void this.refreshNotifications();
    }, 30000);
    eventBusService.on(EventType.OrganizationChanged).subscribe(() => {
      this.notificationsSubject.next([]);
      this.notificationBarMessagesSubject.next([]);
      void this.refreshNotifications();
    });

    // if organization has Orchestration feature enabled, check if there are any licenses assigned to Pointee orchestration
    if (this.authService.hasPermission(Permission.Orchestration)) {
      this.organizationService.organizationConfigurationSubject.subscribe(configuration => {
        if (configuration.orchestration?.licenseCount > 0) {
          this.removeNotificationBarMessage(NotificationBarMessageType.LicenseCountInvalid);
          return;
        }

        this.addNotificationBarMessage({
          type: NotificationBarMessageType.LicenseCountInvalid,
          severity: AlertSeverity.Warning,
          htmlText: `<b>There are no ${this.platformTextsService.get('Blue Prism')} licenses assigned to Pointee orchestration.</b> Pointee cannot start processes.`,
          icon: 'warning',
          tooltip: null,
          contactSupportEnabled: false,
          link: authService.hasPermission(Permission.OrganizationSettings)
            ? { text: 'Change settings', route: this.navigationService.getOrganizationSettingsRoute(SubPageType.OrchestrationSettings) }
            : null,
        });
      });
    }
  }

  public async refreshNotifications(): Promise<void> {
    if (!this.authService.isLoggedIn) {
      return;
    }

    if (this.appConfigurationService.isOnPremise) {
      await this.addSchedulerStatusMessages();
    }

    if (this.areNotificationEnabled) {
      let notifications: Notification[] = await this.getNotifications();
      if (this.organizationService.isDemoOrganization) {
        notifications = notifications.concat(demoNotifications).sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
      }
      this.notificationsSubject.next(notifications);
    }
  }

  async setAllNotificationsRead(): Promise<void> {
    if (this.organizationService.isDemoOrganization) {
      demoNotifications.forEach(n => (n.isRead = true));
    }

    await this.apiService.notifications.setAllNotificationsRead();
    await this.refreshNotifications();
  }

  private async getNotifications(): Promise<Notification[]> {
    const notifications = await this.apiService.notifications.getNotifications();
    const now = new Date().getTime();
    notifications.forEach(n => (n.age = now - n.timestamp.getTime()));
    return notifications;
  }

  private addNotificationBarMessage(message: NotificationBarMessage): void {
    if (this.notificationBarMessagesSubject.value.some(m => m.type === message.type)) {
      return;
    }
    const notifications = [...this.notificationBarMessagesSubject.value, message];
    this.notificationBarMessagesSubject.next(notifications);
  }

  private removeNotificationBarMessage(type: NotificationBarMessageType): void {
    const notifications = this.notificationBarMessagesSubject.value.filter(m => m.type !== type);
    this.notificationBarMessagesSubject.next(notifications);
  }

  private async addSchedulerStatusMessages(): Promise<void> {
    let schedulerStatus: SchedulerStatus;
    try {
      schedulerStatus = await this.apiService.scheduler.getSchedulerStatus();
    } catch {
      schedulerStatus = null;
    }

    this.addOrRemoveSchedulerNotificationBarMessage(
      schedulerStatus?.isSchedulerRunning === false,
      NotificationBarMessageType.SchedulerDisabled,
      '<b>Orchestration is not enabled or has not been initiated yet.</b> If the problem persists for more than a few minutes, please contact support.'
    );

    this.addOrRemoveSchedulerNotificationBarMessage(
      schedulerStatus?.isSchedulerRunning === true && schedulerStatus?.isRpaDatabaseConnected === false,
      NotificationBarMessageType.SchedulerRpaDbNotConnected,
      `<b>Connection to the ${this.platformTextsService.get('Blue Prism')} database has been lost.</b> The data you see may not be up to date.`
    );

    this.addOrRemoveSchedulerNotificationBarMessage(
      schedulerStatus?.isSchedulerRunning === true && schedulerStatus?.isRpaServerConnected === false,
      NotificationBarMessageType.SchedulerRpaServerNotConnected,
      `<b>Pointee is unable to start and stop processes.</b> Connection to ${this.platformTextsService.get('Blue Prism')} has been lost.`
    );

    this.addOrRemoveSchedulerNotificationBarMessage(
      schedulerStatus?.isSchedulerRunning === true && schedulerStatus?.isSchedulerFastEnough === false,
      NotificationBarMessageType.SchedulerIsSlow,
      '<b>Orchestration is slow.</b> Some processes may not be started on time.'
    );
  }

  private addOrRemoveSchedulerNotificationBarMessage(add: boolean, type: NotificationBarMessageType, htmlText: string, tooltip: string = null): void {
    if (add) {
      const message: NotificationBarMessage = {
        type,
        icon: 'warning',
        severity: AlertSeverity.Error,
        htmlText,
        tooltip,
        contactSupportEnabled: true,
      };
      this.addNotificationBarMessage(message);
    } else {
      this.removeNotificationBarMessage(type);
    }
  }
}

const demoNotifications: Notification[] = [
  {
    id: '1',
    type: NotificationType.Custom,
    message: '<b>ID128 New employee onboarding</b> failed logging in due to an expired Windows password.',
    timestamp: DateHelper.subtractTime(new Date(), 10, DateTimeUnit.Minute),
    age: 10 * 60 * 1000,
    isRead: false,
  },
  {
    id: '2',
    type: NotificationType.Custom,
    message: '<b>vm003</b> has been rebooted after it stopped responding.',
    timestamp: DateHelper.subtractTime(new Date(), 27, DateTimeUnit.Minute),
    age: 27 * 60 * 1000,
    isRead: false,
  },
  {
    id: '3',
    type: NotificationType.Custom,
    message: '<b>ID376 Checkin amount receivable</b> could not be started.',
    timestamp: DateHelper.subtractTime(new Date(), 98, DateTimeUnit.Minute),
    age: 98 * 60 * 1000,
    isRead: true,
  },
  {
    id: '4',
    type: NotificationType.Custom,
    message: '<b>ID328 Purchase validation</b> has been stopped due to a low success rate (48% on the last 10 items).',
    timestamp: DateHelper.subtractTime(new Date(), 224, DateTimeUnit.Minute),
    age: 224 * 60 * 1000,
    isRead: true,
  },
  {
    id: '5',
    type: NotificationType.Custom,
    message: '<b>ID258 Order resolution SAP</b> had been stuck in stage Get next item for over 5 minutes. The process has been restarted.',
    timestamp: DateHelper.subtractTime(new Date(), 300, DateTimeUnit.Minute),
    age: 300 * 60 * 1000,
    isRead: true,
  },
  {
    id: '6',
    type: NotificationType.Custom,
    message: '<b>vm011</b> has been quarantined',
    timestamp: DateHelper.subtractTime(new Date(), 477, DateTimeUnit.Minute),
    age: 477 * 60 * 1000,
    isRead: true,
  },
  {
    id: '6',
    type: NotificationType.Custom,
    message: '<b>vm011</b> has been rebooted after it stopped responding.',
    timestamp: DateHelper.subtractTime(new Date(), 496, DateTimeUnit.Minute),
    age: 496 * 60 * 1000,
    isRead: true,
  },
  {
    id: '7',
    type: NotificationType.Custom,
    message: '<b>ID298 Invoice processing SAP</b> failed logging in due to an expired Windows password.',
    timestamp: DateHelper.subtractTime(new Date(), 533, DateTimeUnit.Minute),
    age: 533 * 60 * 1000,
    isRead: true,
  },
  {
    id: '8',
    type: NotificationType.Custom,
    message: 'A maintenance window (stopping all process orchestration) just ended. Pointee has resumed orchestration and is running processes according to the schedule you’ve set up.',
    timestamp: DateHelper.subtractTime(new Date(), 666, DateTimeUnit.Minute),
    age: 666 * 60 * 1000,
    isRead: true,
  },
  {
    id: '9',
    type: NotificationType.Custom,
    message: 'A maintenance window just started. Pointee will not run any processes until 2:00 PM.',
    timestamp: DateHelper.subtractTime(new Date(), 690, DateTimeUnit.Minute),
    age: 690 * 60 * 1000,
    isRead: true,
  },
  {
    id: '10',
    type: NotificationType.Custom,
    message: '<b>ID258 Order resolution SAP</b> has been quarantined.',
    timestamp: DateHelper.subtractTime(new Date(), 1023, DateTimeUnit.Minute),
    age: 1023 * 60 * 1000,
    isRead: true,
  },
  {
    id: '11',
    type: NotificationType.Custom,
    message: '<b>ID258 Order resolution SAP</b> terminated.',
    timestamp: DateHelper.subtractTime(new Date(), 1024, DateTimeUnit.Minute),
    age: 1024 * 60 * 1000,
    isRead: true,
  },
  {
    id: '12',
    type: NotificationType.Custom,
    message:
      'A maintenance window (stopping all processes with the tag <i>SAP</i>) just ended. Pointee has resumed orchestration and is running those processes according to the schedule you’ve set up.',
    timestamp: DateHelper.subtractTime(new Date(), 1500, DateTimeUnit.Minute),
    age: 1500 * 60 * 1000,
    isRead: true,
  },
  {
    id: '13',
    type: NotificationType.Custom,
    message: 'A maintenance window just started. Pointee will not run any processes with the tag <i>SAP</i> until 2.30 PM.',
    timestamp: DateHelper.subtractTime(new Date(), 2000, DateTimeUnit.Minute),
    age: 2000 * 60 * 1000,
    isRead: true,
  },
];
