@if (form) {
  <form [formGroup]="form">
    <div class="inline-content">
      <shared-calendar-dropdown name="date" formControlName="date" [minDate]="today" [maxDate]="endOfNextYear"></shared-calendar-dropdown>
      @if (dateTimeDelimiter) {
        <span>{{ dateTimeDelimiter }}</span>
      }
      @if (showTime) {
        <shared-time-picker name="time" formControlName="time"></shared-time-picker>
      }
    </div>
  </form>
}
