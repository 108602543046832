import { Directive, Input, TemplateRef, ElementRef, HostListener, ComponentRef, OnDestroy } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { CustomTooltipComponent } from '../components/custom-tooltip/custom-tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';

@Directive({
  selector: '[customToolTip]',
})
export class ToolTipRendererDirective implements OnDestroy {
  /**
   * This will be used to show tooltip or not
   * This can be used to show the tooltip conditionally
   */
  @Input() showToolTip = true;

  //If this is specified then specified text will be showin in the tooltip
  @Input(`customToolTip`) text: string;

  //If this is specified then specified template will be rendered in the tooltip
  @Input() contentTemplate: TemplateRef<any>;

  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef) {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: 4,
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: 4,
      },
    ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave') hideTooltip() {
    this.closeToolTip();
  }

  ngOnDestroy() {
    this.overlayRef.dispose();
  }

  private showTooltip() {
    if (!this.showToolTip) {
      return;
    }

    //attach the component if it has not already attached to the overlay
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      const tooltipPortal = new ComponentPortal(CustomTooltipComponent);
      const tooltipRef = this.overlayRef.attach(tooltipPortal);
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
    }
  }

  /**
   * This method will close the tooltip by detaching the component from the overlay
   */
  private closeToolTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
