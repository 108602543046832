import { Injectable } from "@angular/core";
import { ApiService } from "./api/api.service";
import { AuthService } from "./auth.service";
import { EventBusService, EventType } from "./event-bus.service";
import { BehaviorSubject } from "rxjs";
import { MonitoringStatistics } from "../types/monitoring-statistics.type";

@Injectable({ providedIn: 'root' })
export class MonitoringStatisticsService {
  private statisticsSubject = new BehaviorSubject<MonitoringStatistics>(null);

  public get statistics$() {
    return this.statisticsSubject.asObservable();
  }

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    eventBusService: EventBusService,
  ) {
    void this.refresh();
    setInterval(async () => await this.refresh(), 30000);
    eventBusService.on(EventType.OrganizationChanged).subscribe(() => {
      this.statisticsSubject.next(null);
      void this.refresh();
    });
  }

  async refresh(): Promise<void> {
    if (!this.authService.isLoggedIn) {
      return;
    }

    try {
      const statistics = await this.apiService.monitoring.getStatistics();
      this.statisticsSubject.next(statistics);
    } catch (error) {
      // ignored
    }
  }
}
