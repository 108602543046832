import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]',
})
export class NumberOnlyDirective {
  @Input() decimals: string;

  @HostListener('keydown', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    const { key } = event;
    if (this.isSpecialOperation(event) || !this.isKeyPrintable(event)) {
      return;
    }
    const target = event.target as HTMLInputElement;
    const newValue = this.getNewValue(target, key);
    const validatedValue = this.handleExcessDecimals(newValue);
    if (validatedValue === null) {
      event.preventDefault();
    } else if (validatedValue !== newValue) {
      event.preventDefault();
      setTimeout(() => (target.value = validatedValue), 0); // Update asynchronously to avoid interrupting the event flow
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text');
    const target = event.target as HTMLInputElement;
    const newValue = this.getNewValue(target, pastedText);
    const validatedValue = this.handleExcessDecimals(newValue);
    if (validatedValue === null) {
      event.preventDefault();
    } else if (validatedValue !== newValue) {
      event.preventDefault();
      setTimeout(() => (target.value = validatedValue), 0); // Update asynchronously to avoid interrupting the event flow
    }
  }

  private getNewValue(target: HTMLInputElement, str: string): string {
    const { value = '', selectionStart, selectionEnd } = target;
    return [...value.split('').splice(0, selectionStart), str, ...value.split('').splice(selectionEnd)].join('');
  }

  private handleExcessDecimals(value: string): string | null {
    const regex = this.decimals && this.decimals !== '0' ? new RegExp(`^-?\\d*(,|\\.)?\\d{0,${parseInt(this.decimals)}}`) : /^-?\d*/;
    const match = value.match(regex);
    return match ? match[0] : null;
  }

  private isSpecialOperation(event: KeyboardEvent): boolean {
    const { keyCode, ctrlKey, metaKey } = event;
    // allow ctr-A/C/V/X/Y/Z
    const keysACVXYZ = [65, 67, 86, 88, 89, 90];
    if ((ctrlKey || metaKey) && keysACVXYZ.includes(keyCode)) {
      return true;
    }
    return false;
  }

  private isKeyPrintable(event: KeyboardEvent): boolean {
    const { keyCode } = event;
    return (
      (keyCode > 47 && keyCode < 58) || // number keys
      keyCode === 32 ||
      keyCode === 13 || // spacebar & return key(s)
      (keyCode > 64 && keyCode < 91) || // letter keys
      (keyCode > 95 && keyCode < 112) || // numpad keys
      (keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
      (keyCode > 218 && keyCode < 223)
    ); // [\]' (in order)
  }
}
