@if (showTrend) {
  <div class="trend-content">
    <div [attr.class]="class" tooltip="{{trend | format:trendType:true:true}}" [display]="displayTooltip">
      @if (displayIcon) {
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path stroke-linecap="round" stroke-width="3" [attr.d]="iconPath" />
        </svg>
      }
      <span>{{ trend | format:trendType:true }}</span>
    </div>
  </div>
}
