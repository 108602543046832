import { Injectable } from '@angular/core';
import { Platform } from '../enums/generated.enums';
import { TextHelper } from '../helpers/text.helper';
import { Data } from '../types/data.type';

type StringDictionary = Record<string, string>;

@Injectable({ providedIn: 'root' })
export class PlatformTextsService {
  private readonly unifiedDictKey = 'unified';
  private platformsDictionary: Record<string, StringDictionary> = {};

  private get platform(): Platform {
    return this.data.platform;
  }

  constructor(private data: Data) {
    this.platformsDictionary[Platform.BluePrism] = this.createBluePrismDict();
    this.platformsDictionary[Platform.UiPath] = this.createUiPathDict();
    this.platformsDictionary[this.unifiedDictKey] = this.createUnifiedDict();
  }

  private createBluePrismDict(): StringDictionary {
    const dictionary: StringDictionary = {};
    dictionary.platform = 'Blue Prism';
    dictionary['main-page'] = 'Main Page';
    return dictionary;
  }

  private createUiPathDict(): StringDictionary {
    const dictionary: StringDictionary = {};
    this.addAllForms(dictionary, 'Blue Prism', 'UiPath');
    this.addAllForms(dictionary, 'session', 'job');
    this.addAllForms(dictionary, 'item', 'transaction');
    this.addAllForms(dictionary, 'items count', 'transactions count');
    this.addAllForms(dictionary, 'page', 'file');
    this.addAllForms(dictionary, 'stage', 'activity', 'stages', 'activities');
    this.addAllForms(dictionary, 'resource', 'machine');
    this.addAllForms(dictionary, 'user', 'robot');
    dictionary.platform = 'UiPath';
    dictionary['main-page'] = 'Main';
    dictionary.Completed = 'Successful';
    dictionary.Terminated = 'Faulted';
    return dictionary;
  }

  private createUnifiedDict(): StringDictionary {
    const dictionary: StringDictionary = {};
    this.addAllForms(dictionary, 'item', 'task');
    return dictionary;
  }

  private addAllForms(dictionary: StringDictionary, key: string, value: string, keyInPlural?: string, valueInPlural?: string) {
    const keys = keyInPlural ?? `${key}s`;
    const values = valueInPlural ?? `${value}s`;
    dictionary[key] = value;
    dictionary[keys] = values;
    dictionary[TextHelper.firstLetterToUpperCase(key)] = TextHelper.firstLetterToUpperCase(value);
    dictionary[TextHelper.firstLetterToUpperCase(keys)] = TextHelper.firstLetterToUpperCase(values);
  }

  get(key: string, unified = false): string {
    const platformKey = unified ? this.unifiedDictKey : this.platform;
    return this.platformsDictionary[platformKey]?.[key] ?? key;
  }

  translate(text: string): string {
    return text
      .split(/\s+/)
      .map(word => this.get(word))
      .join(' ');
  }
}
