import { Directive, ViewContainerRef, TemplateRef, AfterViewInit } from '@angular/core';

@Directive({ selector: '[inView]' })
export class InViewDirective implements AfterViewInit {
  private alreadyRendered: boolean; // cheking if visible already
  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  ngAfterViewInit() {
    const commentElement = this.viewContainerRef.element.nativeElement as HTMLElement;
    const elementToObserve = commentElement?.parentElement;
    if (elementToObserve) {
      this.setMinWidthHeight(elementToObserve);
      const options: IntersectionObserverInit = { root: null, rootMargin: '0px', threshold: [0, 0.1, 0.9, 1] };
      const observer = new IntersectionObserver(this._callback, options);
      observer.observe(elementToObserve);
    }
  }

  private _callback = (entries: any[], observer: any) => {
    entries.forEach(entry => {
      this.renderContents(entry.isIntersecting);
    });
  };

  private renderContents(isInView: boolean) {
    if (isInView && !this.alreadyRendered) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.alreadyRendered = true;
    }
    if (!isInView && this.alreadyRendered) {
      this.viewContainerRef.clear();
      this.alreadyRendered = false;
    }
  }

  private setMinWidthHeight(element: any) {
    // prevent issue being visible all together
    const style = window.getComputedStyle(element);
    const [width, height] = [parseInt(style.width), parseInt(style.height)];
    !width && (element.style.minWidth = '40px');
    !height && (element.style.minHeight = '100px');
  }
}
