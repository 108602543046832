<!-- SEARCH INPUT -->
<div class="pointee-search-input" [class.ghost]="border === BorderStyle.Ghost">
  <input
    #searchInput
    type="text"
    name="search-input"
    class="search-input"
    [class.bevel]="border === BorderStyle.Bevel"
    [(ngModel)]="searchValue"
    placeholder="{{placeholder || 'Search...'}}"
    maxlength="100"
    autocomplete="off"
    autofocus
  />
    <span class="search-input-icon">
      <svg-icon key="search"></svg-icon>
    </span>
    @if (searchValue?.length > 0 || closeAlwaysVisible) {
      <span class="clear-icon" (click)="clearSearch($event)">
        <svg-icon key="close" fontSize="12px"></svg-icon>
      </span>
    }
  </div>
