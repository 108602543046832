<shared-modal [modalTitle]="'Keyboard shortcuts for the process map'">
  <table modalBody>
    <tbody>
      @if (processMapOnly) {
        <tr>
          <td>Zoom in</td>
          <td class="text-end">
            <span class="keyboard-hotkeys">+</span>&nbsp;&nbsp;or<span class="keyboard-hotkeys">Ctrl</span>
            <span class="keyboard-hotkeys">mouse wheel up</span>
          </td>
        </tr>
        <tr>
          <td>Zoom out</td>
          <td class="text-end">
            <span class="keyboard-hotkeys">-</span>&nbsp;&nbsp;or<span class="keyboard-hotkeys">Ctrl</span>
            <span class="keyboard-hotkeys">mouse wheel down</span>
          </td>
        </tr>
        <tr>
          <td>Zoom to 100%</td>
          <td class="text-end">
            <span class="keyboard-hotkeys">Ctrl</span>
            <span class="keyboard-hotkeys">0</span>
          </td>
        </tr>
        <tr>
          <td>Fit to screen</td>
          <td class="text-end">
            <span class="keyboard-hotkeys">Ctrl</span>
            <span class="keyboard-hotkeys">1</span>
          </td>
        </tr>
        <tr>
          <td>Move view down</td>
          <td class="text-end">
            <span class="keyboard-hotkeys">&#8595;</span>
          </td>
        </tr>
        <tr>
          <td>Move view up</td>
          <td class="text-end">
            <span class="keyboard-hotkeys">&#8593;</span>
          </td>
        </tr>
        <tr>
          <td>Move view left</td>
          <td class="text-end">
            <span class="keyboard-hotkeys">&#8592;</span>
          </td>
        </tr>
        <tr>
          <td>Move view right</td>
          <td class="text-end">
            <span class="keyboard-hotkeys">&#8594;</span>
          </td>
        </tr>
      }
      <tr>
        <td>Jump to ...</td>
        <td class="text-end">
          <span class="keyboard-hotkeys">Ctrl</span>
          <span class="keyboard-hotkeys">X</span>
        </td>
      </tr>
      <tr>
        <td>Keyboard shortcuts (this window)</td>
        <td class="text-end">
          <span class="keyboard-hotkeys">Shift</span>
          <span class="keyboard-hotkeys">?</span>
        </td>
      </tr>
    </tbody>
  </table>
</shared-modal>
