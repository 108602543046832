@if (data && data.legend && data.legend.length > 0) {
  <div class="graph-legend">
    @for (legend of orderedLegend; track legend; let i = $index) {
      <div class="legend-item">
        @if (!data.invertLegendOrder) {
          <span class="pointee-colored-circle" [style.background-color]="data.colors[i]"></span>
        } @else {
          <span class="pointee-colored-circle" [style.background-color]="data.colors[data.colors.length - i - 1]"></span>
        }
        <span class="legend-text" [tooltip]="orderedLegend[i]" [options]="ellipsisTooltipOptions" (mouseover)="onMouseOver($event)">{{ orderedLegend[i] }}</span>
      </div>
    }
  </div>
}
<div class="chart-js" [style.height]="height">
  <canvas #barChart [class.grab-cursor]="isZoomingRequired"></canvas>
</div>
@if (zoomEnabled && isZoomingRequired) {
  <div class="zoom-container">
    <app-zoom
      [zoom]="currentZoom"
      [defaultZoom]="defaultZoom"
      [min]="minZoom"
      [max]="maxZoom"
      [step]="zoomStep"
      [defaultButtons]="[ZoomDefaultButton.FitToScreen, ZoomDefaultButton.ZoomOut, ZoomDefaultButton.ResetZoom, ZoomDefaultButton.ZoomIn]"
      [extraButtons]="panButtons"
      (zoomChange)="onZoomChanged($event)"
      (fitToScreenClick)="onFitToScreenClicked()"
    >
    </app-zoom>
  </div>
}
