import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged, fromEvent, map, scan, tap } from 'rxjs';
import { PageType } from '../enums/page-type.enum';
import { Data } from '../types/data.type';
import { NavigationService } from './navigation.service';

@Injectable({ providedIn: 'root' })
export class LoadingScreenService {
  get isLoadingScreenVisible(): boolean {
    return this._isLoadingScreenVisible || (this.navigationService.isOnPage(PageType.RuntimeAnalysis) && (!this.data || !this.data.loaded || this.data.reloading) && !this.data.canNotBeLoaded);
  }

  get isLoadingScreenVisible$(): Observable<boolean> {
    return this._showRequestCountSubject.asObservable().pipe(
      scan((acc, value) => Math.max(0, acc + value), 0),
      map(count => count > 0),
      tap(isVisible => (this._isLoadingScreenVisible = isVisible)),
      distinctUntilChanged(),
    );
  }

  private _showRequestCountSubject = new BehaviorSubject<number>(0);
  private _isLoadingScreenVisible = false;

  constructor(private data: Data, private navigationService: NavigationService) {
    fromEvent(window, 'offline').subscribe(() => this._showRequestCountSubject.next(-1000));
  }

  showLoadingScreen(): void {
    this._showRequestCountSubject.next(1);
  }

  hideLoadingScreen(): void {
    this._showRequestCountSubject.next(-1);
  }

  callActionWithLoadingScreen(action: () => void): void {
    try {
      this.showLoadingScreen();
      action();
    }
    finally {
      this.hideLoadingScreen();
    }
  }

  async callAsyncActionWithLoadingScreen(asyncAction: () => Promise<void>): Promise<void> {
    try {
      this.showLoadingScreen();
      await asyncAction();
    }
    finally {
      this.hideLoadingScreen();
    }
  }

  async run<T>(func: () => Promise<T>): Promise<T> {
    this.showLoadingScreen();
    try {
      return await func();
    } finally {
      this.hideLoadingScreen();
    }
  }

  async showFakeLoading(durationInMs: number): Promise<void> {
    this.showLoadingScreen();
    return await new Promise(resolve =>
      setTimeout(() => {
        this.hideLoadingScreen();
        resolve();
      }, durationInMs),
    );
  }
}
