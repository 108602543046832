import { Subject } from 'rxjs';
import { DeviceType } from '../enums/device-type.enum';

export class Global {
  private static _deviceType: DeviceType = DeviceType.desktop;
  static os = '';
  static isMacLike = false;
  static browserName = '';
  static browserVersion = '';
  static localeId = '';
  static mobileBreakpoint = 768;
  static tabletBreakpoint = 992;
  static desktopBreakpoint = 1366;

  static get deviceType(): DeviceType {
    return this._deviceType;
  }

  static set deviceType(value: DeviceType) {
    if (this._deviceType !== value) {
      this._deviceType = value;
      this.deviceTypeHasChanged.next(value);
    }
  }

  static get deviceTypeHasChanged$() {
    return this.deviceTypeHasChanged.asObservable();
  }

  static get isMobile(): boolean {
    return this.deviceType === DeviceType.phone;
  }

  // RxJS Subject for device type change
  private static deviceTypeHasChanged = new Subject<DeviceType>();

  static initialize(localeId: string): () => void {
    return () => {
      Global.os = Global.detectOperationSystem();
      Global.isMacLike = (/(Mac|iPhone|iPod|iPad)/i.exec(navigator.userAgent)) ? true : false;
      Global.browserName = Global.detectBrowserName();
      Global.browserVersion = Global.detectBrowserVersion();
      Global.localeId = localeId;
    };
  }

  static detectPopupBlocker(): boolean {
    const popup = window.open('', '', 'height=100,width=100');
    if (!popup) return true;
    popup.close();
    return false;
  }

  private static detectOperationSystem(): string {
    if (navigator.userAgent.includes('Win')) return 'Windows';
    else if (navigator.userAgent.includes('Mac')) return 'Mac';
    else if (navigator.userAgent.includes('X11')) return 'UNIX';
    else if (navigator.userAgent.includes('Linux')) return 'Linux';
    else return '';
  }

  private static detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.includes('edge'):
        return 'edge';
      case agent.includes('opr') && !!(window as any).opr:
        return 'opera';
      case agent.includes('chrome') && !!(window as any).chrome:
        return 'chrome';
      case agent.includes('trident'):
        return 'ie';
      case agent.includes('firefox'):
        return 'firefox';
      case agent.includes('safari'):
        return 'safari';
      default:
        return 'other';
    }
  }

  private static detectBrowserVersion() {
    const userAgent = navigator.userAgent;
    let tem: RegExpMatchArray;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent);
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }
}
