@if (data && data.datasets?.length > 1) {
  <div class="graph-legend">
    @for (dataset of data.datasets; track dataset; let i = $index) {
      <div class="legend-item">
        <span class="pointee-colored-circle" [style.background-color]="dataset.color"></span>
        <span class="legend-text">{{ dataset.label }}</span>
      </div>
    }
  </div>
}
<div class="chart-js" [style.height]="height">
  <canvas #lineChart></canvas>
</div>
