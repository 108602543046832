import { Observable } from 'rxjs';
import { FeatureFlags, Permission } from 'src/app/shared/enums/generated.enums';
import { PageType, SubPageType } from '../enums/page-type.enum';

export interface MenuSettings {
  sections: MenuSection[];
  isMenuExpanded?: boolean;
}

export interface MenuSection {
  label: string;
  type: 'items' | 'actions' | 'submenu';
  items: MenuItem[];
  parentRoute?: string[];
}

export interface MenuItem {
  // itemType: 'link' | 'ext' | 'fun';
  route?: string[] | undefined; // could be empty if it's a function
  label: string;
  icon: string;
  active?: boolean;
  permission?: Permission;
  featureFlag?: FeatureFlags;
  disabled?: boolean;
  hide?: boolean; // hide menu item from the menu
  badge$?: Observable<number | null>;
  badgeType?: BadgeType; // type of badge -- count is non highlighted number
  hideBadgeWhenOnPage?: boolean;
  onClick?: ($event: Event) => void;

  pageType?: PageType;
  tabs?: MenuTab[];
  onSubPageChange?: (subPageType: SubPageType) => void;
}

export interface MenuTab {
  label: string;
  route: string[];
  subPageType: SubPageType;
  group?: string;
  permissions?: Permission[];
  featureFlags?: FeatureFlags[];
  isHidden?: boolean;
}

export enum BadgeType {
  Warning = 'warning',
  Count = 'count',
}
