import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, distinctUntilChanged } from 'rxjs';
import { NavigationService } from '../services/navigation.service';
import { FilterDefinition, FilterItem, FilterItemType, FilterItemValue } from './filter-definition.type';
import { replace } from '../helpers/array.helper';

@Injectable()
export class FilteringService implements OnDestroy {
  private filterDefinitionSubject = new ReplaySubject<FilterDefinition>(1);
  private filterDefinition: FilterDefinition;

  constructor(private navigationService: NavigationService) {}

  ngOnDestroy(): void {
    this.filterDefinitionSubject.complete();
  }

  filterDefinitionChanged$(filters?: string[]): Observable<FilterDefinition> {
    return this.filterDefinitionSubject.asObservable().pipe(
      distinctUntilChanged((a, b) => {
        if (filters == null) {
          return false;
        }

        if (a == null || b == null) {
          return a == b;
        }

        return filters.every(f => JSON.stringify(a.getFilterValue(f)) === JSON.stringify(b.getFilterValue(f)));
      }),
    );
  }

  initializeFilters(filters: FilterItem[]): void {
    this.setNewFilters(filters);
  }

  isFilterActive(name: string): boolean {
    return this.filterDefinition.isFilterActive(name);
  }

  async selectFilterValue(name: string, value: string | string[], source: string = null): Promise<void> {
    const filter = this.filterDefinition.getFilter(name);
    if (filter.type !== FilterItemType.Select && filter.type !== FilterItemType.MultiSelect) {
      throw new Error('Filter type is not supported.');
    }

    if (JSON.stringify(filter.value) === JSON.stringify(value)) {
      return;
    }

    const newFilter: FilterItem = filter.type === FilterItemType.Select ? { ...filter, value: value as string, source } : { ...filter, value: value as string[], source };

    await this.updateFilterAndPublish(newFilter);
  }

  async setFilterValue(name: string, value: FilterItemValue, source = ''): Promise<void> {
    const filter = this.filterDefinition.getFilter(name);
    if (filter.type !== FilterItemType.Simple) {
      throw new Error('Filter type is not supported.');
    }

    if (JSON.stringify(filter.value?.id) === JSON.stringify(value?.id)) {
      return;
    }

    const newFilter = {
      ...filter,
      value,
      source,
    };

    await this.updateFilterAndPublish(newFilter);
  }

  async clearFilter(name: string): Promise<void> {
    if (!this.filterDefinition.isFilterActive(name)) {
      return;
    }

    const filter = this.filterDefinition.getFilter(name);
    const newFilter: FilterItem = { ...filter, value: null, source: null };
    await this.updateFilterAndPublish(newFilter);
  }

  async updateFilterItems(name: string, items: FilterItemValue[], selectedValue?: any): Promise<void> {
    const filter = this.filterDefinition.getFilter(name);
    if (filter.type !== FilterItemType.Select && filter.type !== FilterItemType.MultiSelect) {
      throw new Error('Only select type filters are supported.');
    }

    const newFilter: FilterItem = {
      ...filter,
      items: items,
      value: selectedValue,
    };

    await this.updateFilterAndPublish(newFilter);
  }

  private setNewFilters(filters: FilterItem[]): void {
    this.filterDefinition = new FilterDefinition(filters);
    this.filterDefinitionSubject.next(this.filterDefinition);
  }

  private async updateFilterAndPublish(newFilter: FilterItem): Promise<void> {
    const filters = [...this.filterDefinition.filters];
    const oldFilter = filters.find(f => f.name === newFilter.name);
    replace(filters, oldFilter, newFilter);
    this.setNewFilters(filters);
    await this.updateQueryParams(filters);
  }

  private async updateQueryParams(filters: FilterItem[]): Promise<void> {
    const queryParams: Record<string, any> = {};
    for (const filter of filters.filter(f => f.queryParamName != null)) {
      if (filter.type === FilterItemType.Simple) {
        queryParams[filter.queryParamName] = filter.value?.id.toString();
      } else if (filter.type === FilterItemType.MultiSelect) {
        queryParams[filter.queryParamName] = filter.value && Array.isArray(filter.value) ? filter.value.join(',') : null;
      } else {
        queryParams[filter.queryParamName] = filter.value;
      }
    }

    await this.navigationService.addQueryParams(queryParams);
  }
}
