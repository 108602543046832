import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseModalDialogDirective } from 'src/app/shared/directives/base-modal-dialog.directive';
import { ModalSize } from 'src/app/shared/enums/modal-size.enum';
import { ModalCloseType } from '../../modal/modal-close-type.enum';
import { ClipboardService } from '../../../services/clipboard.service';

export enum EmailType {
  Activation = 'Activation',
  PasswordReset = 'Password reset',
}

@Component({
  selector: 'app-user-activation-link',
  templateUrl: './user-activation-link.component.html',
  styleUrls: ['./user-activation-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserActivationLinkComponent extends BaseModalDialogDirective {
  @Input() link: string;
  @Input() userName: string;
  @Input() isEmailServerConfigured: boolean;
  @Input() emailType = EmailType.Activation;

  get size(): ModalSize {
    return ModalSize.Small;
  }

  get closeType(): ModalCloseType {
    return ModalCloseType.Safe;
  }

  constructor(private clipboardService: ClipboardService) {
    super();
  }

  async copyLink(): Promise<void> {
    await this.clipboardService.writeText(this.link, 'Invitation link copied');
  }
}
