import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActiveElement, Chart, ChartEvent, ChartType, Point, TooltipItem } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import ChartZoomPlugin from 'chartjs-plugin-zoom';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { DateHelper } from 'src/app/shared/helpers/date.helper';
import { MathHelper } from 'src/app/shared/helpers/math.helper';
import { FormatHelper } from '../../../helpers/format.helper';
import { API_CALL_DEBOUNCE_TIME } from '../../../types/constants';
import { DateRange } from '../../../types/date-range.type';
import { ZoomChangeSource } from '../../zoom/zoom-change-source.enum';
import { ZoomChangedEvent } from '../../zoom/zoom-changed-event.type';
import { ZoomComponentButtonPosition } from '../../zoom/zoom-component-button-position.enum';
import { ZoomComponentButton } from '../../zoom/zoom-component-button.type';
import { ZoomDefaultButton } from '../../zoom/zoom-default-button.enum';
import { ChartColor } from '../chart-color.enum';
import { getWeekendsAnnotations } from '../plugins/highlight-weekends.plugin';
import { legendMarginPlugin } from '../plugins/legend-margin.plugin';
import { TimeSeriesData, TimeSeriesDataLoader, TimeSeriesConfiguration, TimeSeriesType } from './time-series-chart.type';

interface DataRequest {
  range?: DateRange;
  force?: boolean;
  isInternal: boolean;
}

@Component({
  selector: 'app-time-series-chart',
  templateUrl: './time-series-chart.component.html',
  styleUrls: ['./time-series-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSeriesChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() data: TimeSeriesData | TimeSeriesDataLoader;
  @Input() configuration: TimeSeriesConfiguration;
  @Input() type: TimeSeriesType = 'bar';
  @Input() tooltipsEnabled = true;
  @Input() zoomEnabled = true;
  @Input() minYAxisWidth = 0;
  @Input() annotations: AnnotationOptions[];

  chart: Chart;
  currentZoom = 1;

  panButtons: ZoomComponentButton[] = [
    {
      id: 'pan-left',
      icon: 'arrow-left-light',
      position: ZoomComponentButtonPosition.Start,
      disabled: () => this.chart.scales.x.min <= this.configuration.from.getTime(),
      action: () => this.onPanClicked(1),
    },
    {
      id: 'pan-right',
      icon: 'arrow-right-light',
      position: ZoomComponentButtonPosition.End,
      disabled: () => this.chart.scales.x.max >= this.configuration.to.getTime(),
      action: () => this.onPanClicked(-1),
    },
  ];

  readonly ZoomDefaultButton = ZoomDefaultButton;
  readonly zoomStep = 0.15;
  readonly defaultZoom = 1;
  maxZoom = 24000;

  private readonly minTimeRange = 60 * 60 * 1000; // 1 hour
  private _chartData: TimeSeriesData;
  private _fetchDataRequest$ = new Subject<DataRequest>();

  @ViewChild('chartCanvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuration != null) {
      this.updateChartDefinition();
    }

    if (changes.annotations != null) {
      this.refreshAnnotations();
      this.chart?.update();
    }

    if (changes.data != null) {
      const data = changes.data.currentValue;
      if (data != null && typeof data !== 'function') {
        this._chartData = data;
        this.updateChartData();
      }
    }
  }

  ngOnInit(): void {
    this._fetchDataRequest$
      .pipe(
        distinctUntilChanged((a, b) => !b.force && a.range.start === b.range.start && a.range.end === b.range.end),
        debounceTime(API_CALL_DEBOUNCE_TIME),
      )
      .subscribe(request => this.fetchNewData(request.range, request.isInternal));
  }

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  ngOnDestroy(): void {
    this._fetchDataRequest$.complete();
    this.chart?.destroy();
    this.chart = null;
  }

  initializeChart(): void {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.type,
      data: {
        datasets: [],
      },
      plugins: [ChartZoomPlugin, legendMarginPlugin],
      options: {
        maintainAspectRatio: true,
        responsive: true,
        animation: {
          duration: 0,
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x',
        },
        scales: {
          y: {
            stacked: this.type === 'bar',
            beginAtZero: this.configuration.min === 0,
            suggestedMin: this.configuration.min,
            suggestedMax: this.configuration.max,
            grid: {
              drawTicks: false,
            },
            border: {
              display: true,
              dash: [8, 4],
              dashOffset: 10,
            },
            ticks: {
              precision: this.configuration.precision,
              padding: 8,
              callback: value => {
                return FormatHelper.format(value, this.configuration.formatType, false, false, this.configuration.unit);
              },
            },
            afterFit: scale => {
              scale.width = Math.max(this.minYAxisWidth ?? 0, scale.width);
            },
          },
          x: {
            type: 'time',
            stacked: this.type === 'bar',
            grid: {
              display: false,
            },
            border: {
              display: true,
              color: ChartColor.Border,
            },
            position: 'bottom',
            ticks: {
              padding: 8,
              maxTicksLimit: 16,
              autoSkipPadding: 8,
              maxRotation: 45,
              minRotation: 30,
            },
            time: {
              tooltipFormat: DateHelper.is24HFormat ? 'DD T' : 'DD t',
              displayFormats: {
                hour: DateHelper.is24HFormat ? 'MMM d, H:mm' : 'MMM d, h:mm a',
                minute: DateHelper.is24HFormat ? 'H:mm' : 'h:mm a',
                second: DateHelper.is24HFormat ? 'H:mm:ss' : 'h:mm:ss a',
              },
            },
          },
        },
        onResize: (chart: Chart, size: { width: number; height: number }) => {
          if (this.chart && chart.chartArea.width === 0 && size.width > 0) {
            this.requestNewData({ force: true, isInternal: true });
          }
        },
        onClick: (event: ChartEvent, elements: ActiveElement[]) => {
          if (elements.length === 0 || !this.zoomEnabled || !this.isZoomAllowed()) {
            return;
          }

          const start = (this._chartData.points[elements[0].datasetIndex][elements[0].index] as Point).x;
          const end = start + this._chartData.bucketSize;
          this.zoom(start, end, true);
        },
        onHover: (event: ChartEvent, elements: ActiveElement[]) => {
          if (!this.zoomEnabled) {
            return;
          }

          this.setCursor(event, elements.length > 0 && this.isZoomAllowed() ? 'pointer' : 'grab');
        },
        plugins: {
          legend: {
            labels: {
              boxHeight: 8,
              boxWidth: 16,
              font: {
                size: 14,
              },
            },
            reverse: this.configuration.reverseLegend || false,
            onHover: (event: ChartEvent) => {
              this.setCursor(event, 'pointer');
            },
            onLeave: (event: ChartEvent) => {
              this.setCursor(event, 'default');
            },
          },
          tooltip: {
            enabled: () => this.tooltipsEnabled && this._chartData != null,
            boxPadding: 4,
            displayColors: this.configuration.datasets.length > 1,
            callbacks: {
              label: (item: TooltipItem<ChartType>) => this.getTooltipLabel(item),
            },
          },
          zoom: {
            zoom: {
              mode: 'x',
              wheel: {
                enabled: this.zoomEnabled,
                modifierKey: 'ctrl',
              },
              drag: {
                enabled: false,
              },
              onZoomStart: event => {
                const nativeEvent = event.event as WheelEvent;
                return nativeEvent?.deltaY > 0 || this.isZoomAllowed();
              },
              onZoomComplete: () => {
                this.requestNewData();
                this.updateZoomComponent();
              },
            },
            pan: {
              enabled: this.zoomEnabled,
              mode: 'x',
              onPanComplete: () => this.requestNewData(),
            },
          },
          annotation: {
            interaction: {
              intersect: true,
            },
            annotations: [],
          },
        },
      },
    });

    this.updateChartDefinition();
    this.updateChartData();
  }

  refresh(force = false): void {
    this.requestNewData({ force, isInternal: false });
  }

  zoom(start: number, end: number, isInternal = false): void {
    this.chart.zoomScale('x', { min: start, max: end });
    this.requestNewData({
      range: DateRange.fromMs(start, end),
      isInternal,
    });
    this.updateZoomComponent();
  }

  onZoomChanged(event: ZoomChangedEvent): void {
    switch (event.source) {
      case ZoomChangeSource.Step:
        const zoom = event.value - event.oldValue > 0 ? 1 + this.zoomStep : 1 - this.zoomStep;
        this.chart.zoom(zoom);
        this.requestNewData();
        break;
      case ZoomChangeSource.Reset:
        if (this.currentZoom !== this.defaultZoom) {
          this.chart.resetZoom();
        }
        break;
    }

    this.updateZoomComponent();
  }

  onFitToScreenClick(): void {
    if (this.currentZoom !== this.defaultZoom) {
      this.chart.resetZoom();
    }
  }

  onPanClicked(direction: number): void {
    const panStep = 50;
    const value = direction * panStep;
    this.chart.pan({ x: value, y: 0 });
    this.requestNewData();
    this._cdr.detectChanges();
  }

  private getTooltipLabel(item: TooltipItem<ChartType>): string {
    const point = this._chartData.points[item.datasetIndex][item.dataIndex] as Point;
    const label = this.configuration.datasets[item.datasetIndex].label;
    const value = FormatHelper.format(point.y, this.configuration.formatType, false, true, this.configuration.unit);

    const visibleDatasetCount = this.chart.data.datasets.filter(d => d.hidden === false).length;
    return visibleDatasetCount > 1 ? `${label}: ${value}` : value;
  }

  private setCursor(event: ChartEvent, cursor: string): void {
    (event.native.target as HTMLElement).style.cursor = cursor;
  }

  private isZoomAllowed(): boolean {
    return this.chart.scales.x.max - this.chart.scales.x.min > this.minTimeRange;
  }

  private updateZoomComponent(): void {
    if (this.chart?.data?.datasets[0] == null) {
      return;
    }

    const originalDataRange = this.configuration.to.getTime() - this.configuration.from.getTime();
    const dataRange = this.chart.scales.x.max - this.chart.scales.x.min;
    const zoomLevel = originalDataRange / dataRange;
    this.currentZoom = MathHelper.round(zoomLevel, 2);
    this._cdr.detectChanges();
  }

  private requestNewData(request: DataRequest = null): void {
    if (this.chart == null || typeof this.data !== 'function') {
      return;
    }

    const r: DataRequest = {
      range: request?.range ?? DateRange.fromMs(this.chart.scales.x.min, this.chart.scales.x.max),
      force: request?.force ?? false,
      isInternal: request?.isInternal ?? true,
    };

    this._fetchDataRequest$.next(r);
  }

  private async fetchNewData(range: DateRange, isInternal: boolean): Promise<void> {
    if (this.chart.chartArea.width === 0 || typeof this.data !== 'function') {
      return;
    }
    this._chartData = await this.data(range, isInternal);
    this.updateChartData();
  }

  private updateChartDefinition(): void {
    if (this.chart == null || this.configuration == null) {
      return;
    }

    const start = this.configuration.from.getTime();
    const end = this.configuration.to.getTime();

    this.maxZoom = Math.round((end - start) / this.minTimeRange);

    const { scales, plugins } = this.chart.options;
    scales.x.min = start;
    scales.x.max = end;
    plugins.zoom.limits = {
      x: { min: start, max: end },
    };
    this.refreshAnnotations();
    plugins.legend.display = this.configuration.showLegend === true;

    this.chart.data.datasets = this.configuration.datasets.map(d => ({ ...d, data: [] }));

    this.chart.update();
    this.requestNewData({ force: true, isInternal: false });
  }

  private updateChartData(): void {
    if (this.chart == null || this._chartData == null) {
      return;
    }

    this.chart.data.datasets.forEach((d, i) => {
      d.data = this._chartData.points[i] ?? [];
      d.hidden = d.data.length === 0;
    });

    this.chart.update();
  }

  private refreshAnnotations() {
    if (this.chart?.options?.plugins?.annotation == null) {
      return;
    }
    const annotations = this.annotations ?? [];
    const weekendsAnnotations = getWeekendsAnnotations(new DateRange(this.configuration.from, this.configuration.to));
    this.chart.options.plugins.annotation.annotations = annotations.concat(weekendsAnnotations);
  }
}
