<shared-modal [modalTitle]="'Jump to...'">
  <div class="md-float-material form-material" modalBody>
    <shared-search-input (search)="onSearchChanged($event)" (clear)="onSearchInputClear($event)" [searchValue]="searchValue" [placeholder]="'Search for pages or nested processes'" />

    @if (treeControl && treeControl.dataNodes[0]) {
      <div class="process-tree-content">
        <div class="empty-label">
          @if (!treeControl.dataNodes[0].visible) {
            <span class="tree-node-label disabled">We couldn’t find a process or a group with that name</span>
          }
        </div>
        @if (dataSource) {
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="process-tree">
            <!-- tree end nodes-->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="8" [ngClass]="{ hidden: !node.visible }">
              <div class="mat-tree-node tree-leaf" [ngClass]="{ selected: isSelected(node), 'not-found': !node.found }" (click)="setProcess(node)">
                <a [routerLink]="getUrl(node.process.id)" class="tree-node-label">{{ node.process.label }}</a>
              </div>
            </mat-tree-node>
            <!-- end of tree end nodes -->
            <!-- tree root nodes-->
            <mat-tree-node *matTreeNodeDef="let node; when: expandable" matTreeNodePadding matTreeNodePaddingIndent="8" [ngClass]="{ hidden: !node.visible }">
              <div class="mat-tree-node" [ngClass]="{ selected: isSelected(node), 'not-found': !node.found }" (click)="setProcess(node)">
                @if (node.process.masterProcessId) {
                  <a [routerLink]="getUrl(node.process.id)" class="tree-node-label root">{{ 'main-page' | platformTexts }}</a>
                } @else {
                  <a [routerLink]="getUrl(node.process.id)" class="tree-node-label">{{ node.process.label }}</a>
                }
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                  @if (!treeControl.isExpanded(node)) {
                    <svg-icon key="arrow-button-down" class="toggle-icon"></svg-icon>
                  } @else {
                    <svg-icon key="arrow-button-up" class="toggle-icon"></svg-icon>
                  }
                </button>
              </div>
            </mat-tree-node>
            <!-- end of tree root nodes -->
          </mat-tree>
        }
      </div>
    }
  </div>
</shared-modal>
