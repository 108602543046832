import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { User } from '../../types/user.type';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { ResetPasswordResult } from '../../types/set-password-result.type';

@Injectable({ providedIn: 'root' })
export class UsersApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/users`;
  }

  async getUsers(): Promise<User[]> {
    const result = await this.get<User[]>('');
    ApiConversionHelper.convertDates(result, ['lastLogin']);
    return result;
  }

  async createUser(user: User): Promise<ResetPasswordResult> {
    return await this.postWithResult('', user);
  }

  async updateUser(user: User): Promise<void> {
    return await this.put(`${user.id}`, user);
  }

  async deleteUser(user: User): Promise<void> {
    return await this.delete(`${user.id}`);
  }
}
