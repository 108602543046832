<div class="chart-js" #scrollable>
  <canvas #chartCanvas></canvas>
</div>
@if (chart && zoomEnabled) {
  <div class="zoom-container">
    <app-zoom
      [zoom]="currentZoom"
      [defaultZoom]="defaultZoom"
      [min]="1"
      [max]="maxZoom"
      [step]="zoomStep"
      [showShortcutInTooltips]="true"
      [zoomKeyModifier]="'CTRL'"
      [defaultButtons]="[ZoomDefaultButton.FitToScreen, ZoomDefaultButton.ZoomOut, ZoomDefaultButton.ResetZoom, ZoomDefaultButton.ZoomIn]"
      [extraButtons]="panButtons"
      (zoomChange)="onZoomChanged($event)"
      (fitToScreenClick)="onFitToScreenClick()"
      >
    </app-zoom>
  </div>
}
