@if (selectedRange?.start) {
  <div class="calendar-dropdown-container">
    @if (showShortcuts) {
      <div class="day-button-group">
        @for (item of filteredShortcuts; track item) {
          <button class="btn btn-set-interval" [ngClass]="{'selected': item.selected, 'disabled': item.disabled}" [disabled]="item.disabled" (click)="setRangeManually(item)">
            {{item.label}}
          </button>
        }
      </div>
    }
    <div class="inline-calendar-card">
      <mat-calendar
        #calendar
        [selected]="selectedDateRange"
        [minDate]="minDateTime"
        [dateFilter]="dateFilter"
        [dateClass]="setClass()"
        [startAt]="startAt"
        [headerComponent]="customCalendarHeader"
        (selectedChange)="selectedChanged($event)"
        class="calendar"
      ></mat-calendar>
    </div>
  </div>
}
