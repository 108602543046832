@if (isInProgress) {
  <svg viewBox="0 0 32 32" class="progress__svg">
    <circle [attr.r]="radius" cx="16" cy="16" stroke-width="8" class="progress__meter" />
    <circle [style.strokeDasharray]="circumference" [style.strokeDashoffset]="(1 - totalProgress) * circumference" [attr.r]="radius" cx="16" cy="16" stroke-width="8" class="progress__value" />
  </svg>
}

@if (status === ProcessingItemStatus.Uploaded) {
  <svg-icon key="uploaded" class="color-success"></svg-icon>
} @else if (status === ProcessingItemStatus.Successful || status === ProcessingItemStatus.DeletedSuccessfully) {
  <svg-icon key="done-circle" class="color-success"></svg-icon>
} @else if (status === ProcessingItemStatus.Error || status === ProcessingItemStatus.Deleted) {
  <svg-icon key="error-circle" [class.color-warning]="isFatalError === false" [class.color-danger]="isFatalError !== false"></svg-icon>
} @else if (status === ProcessingItemStatus.Cancelled) {
  <svg-icon key="canceled-circle" class="color-danger"></svg-icon>
}
