@if (isOpen) {
  <div class="upload-processing-wrapper in" [ngClass]="{ collapsed: isCollapsed, 'right-gap': isProcessMap }">
    <div class="dialog-content">
      <div class="dialog-header">
        <div class="title">{{ dialogTitle }}</div>
        <button type="button" class="btn btn-icon-only dialog-collapse dialog-header-button" [tooltip]="collapseTooltip" (click)="toggleCollapse()">
          <svg-icon key="chevron-down"></svg-icon>
        </button>
        <button type="button" class="btn btn-icon-only dialog-header-button" [tooltip]="closeTooltip" (click)="close()">
          <svg-icon key="header-close"></svg-icon>
        </button>
      </div>
      <div class="dialog-body">
        @for (item of items; track trackById($index, item)) {
          <div class="upload-processing-body-content">
            <div
              class="upload-process-item"
              [tooltip]="item.fileName"
              [tooltipDisplayTarget]="item.id"
              [showDelay]="500"
              (click)="displayResult(item)"
          [ngClass]="{
            'success': item.isSuccessful || item.isDeletedSuccessfully || item.isUploaded,
            'error': item.isError,
            'cancelled': item.isCancelled,
            'deleted': item.isDeleted
          }"
              >
              @if (item.isUploading || item.isPending || item.isProcessing) {
                <app-processing-progress-circle [status]="item.status" [totalProgress]="item.totalProgress"></app-processing-progress-circle>
                <div class="upload-info">
                  <div [attr.id]="item.id" class="upload-file">
                    <span>{{ item.fileName }}</span>
                  </div>
                  <div class="upload-status">
                    @if (item.isUploading) {
                      <span>Uploading file</span>
                    }
                    @if (item.isPending) {
                      <span>Waiting for server</span>
                    }
                    @if (item.isProcessing) {
                      <span>Preparing analysis</span>
                    }
                    @if (!item.isPending) {
                      <span>&nbsp;({{ item.operationProgress | format:FormatType.Percentage }})</span>
                    }
                  </div>
                </div>
                @if (item.canCancel) {
                  <button type="button" class="btn btn-link processing-cancel-button" (click)="cancel(item)">cancel</button>
                }
              }
              @if (item.isUploaded || item.isSuccessful || item.isError || item.isCancelled || item.isDeleted || item.isDeletedSuccessfully) {
                <app-processing-progress-circle [status]="item.status" [totalProgress]="item.totalProgress" [isFatalError]="item.isFatalError"></app-processing-progress-circle>
                <div class="upload-info">
                  <div [attr.id]="item.id" class="upload-file">{{ item.fileName }}</div>
                </div>
                @if (item.isCancelled) {
                  <div class="upload-status">
                    <span>Canceled</span>
                  </div>
                }
                @if (item.isDeleted || item.isDeletedSuccessfully) {
                  <div class="upload-status">
                    <span>Deleted</span>
                  </div>
                }
                @if (!item.isCancelled && !item.isDeleted && !item.isDeletedSuccessfully) {
                  <shared-info-icon></shared-info-icon>
                }
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}
