export enum CurrencyCode {
  AUD = 'AUD',
  CAD = 'CAD', // canadian dollar
  CZK = 'CZK', // ceska koruna *after
  CHF = 'CHF', // swiss frank
  DKK = 'DKK', // denmark
  EUR = 'EUR',
  GBP = 'GBP', // british pound
  HUF = 'HUF', // magyar
  INR = 'INR', // india rupee,
  ISK = 'ISK', // island *after
  JPY = 'JPY', // japan yen
  NOK = 'NOK', // norway kr
  PLN = 'PLN', // polski zloty * after
  SEK = 'SEK', // sweden kr *after
  USD = 'USD',
}

const symbolMap: Record<string, string> = {
  AUD: '$',
  CAD: '$ ',
  CZK: 'Kč',
  CHF: '₣ ',
  DKK: 'kr. ',
  EUR: '€',
  GBP: '£',
  INR: '₹ ',
  ISK: 'kr',
  JPY: '¥ ',
  NOK: 'kr ',
  PLN: 'zł',
  SEK: 'kr',
  USD: '$',
};

export class CurrencyHelper {
  static readonly defaultCurrencySymbol = symbolMap.EUR;
  static currencySymbol = this.defaultCurrencySymbol;
  static setCurrency(currencyCode: CurrencyCode) {
    this.currencySymbol = CurrencyHelper.getSymbolFromCurrency(currencyCode);
  }

  static setCurrencySymbol(currencySymbol: string) {
    this.currencySymbol = currencySymbol;
  }

  static getSymbolFromCurrency(currencyCode: CurrencyCode): string {
    if (typeof currencyCode !== 'string') {
      return null;
    }

    const code = currencyCode.toUpperCase();
    if (!Object.prototype.hasOwnProperty.call(symbolMap, code)) {
      return null;
    }

    return symbolMap[code];
  }

  static getCodeFromSymbol(currencySymbol: string): string {
    return Object.keys(symbolMap).find(key => symbolMap[key] === currencySymbol);
  }
}
