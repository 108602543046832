<shared-modal>
  <div modalBody class="data-error-wrapper">
    <div class="error-content">
      <div class="error-content-header">
        <svg-icon key="email-error"></svg-icon>
        <h3 class="no-margin">{{ emailType }} email has not been sent</h3>
      </div>
      <p class="no-margin">
        @if (isEmailServerConfigured) {
          The server encountered an error, and an email to {{ userName }} was not sent.
        } @else {
          An email to {{ userName }} was not sent because the email server is not configured.
        }
      </p>
      <p>Please copy and send them the following link so they can activate their account:</p>
      <div class="flex flex-gap-8">
        <input type="text" class="form-control" [value]="link" [selectionStart]="0" [selectionEnd]="link.length" readonly autofocus>
        <button class="btn btn-primary m-0" (click)="copyLink()">Copy link</button>
      </div>
      <button class="btn btn-link" (click)="close()">Close</button>
    </div>
  </div>
</shared-modal>
