import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalCloseType } from '../components/modal/modal-close-type.enum';
import { BaseModalDialogDirective } from '../directives/base-modal-dialog.directive';
import { ModalSize } from '../enums/modal-size.enum';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private ngbModal: NgbModal) {}

  open<TModalDialog extends BaseModalDialogDirective>(type: new (...args: never[]) => TModalDialog, setParameters?: (d: TModalDialog) => void): NgbModalRef {
    const modalRef = this.ngbModal.open(type, this.getModalOptions(type));
    const component = modalRef.componentInstance as TModalDialog;
    if (setParameters) {
      setParameters(component);
    }
    return modalRef;
  }

  isSomeOpen(): boolean {
    return this.ngbModal.hasOpenModals();
  }

  private getModalOptions<TModalDialog extends BaseModalDialogDirective>(type: new (...args: never[]) => TModalDialog): NgbModalOptions {
    const closeType = type.prototype.closeType as ModalCloseType;
    const size = type.prototype.size as ModalSize;

    const allowCloseByEscKey = closeType === ModalCloseType.Normal;
    const allowCloseByOutsideClick = closeType === ModalCloseType.Normal;
    const dialogClass = this.getDialogClass(size);

    return {
      backdrop: allowCloseByOutsideClick ? true : 'static',
      keyboard: allowCloseByEscKey,
      centered: true,
      scrollable: true,
      modalDialogClass: dialogClass,
    };
  }

  private getDialogClass(size: ModalSize): string {
    switch (size) {
      case ModalSize.Small:
        return 'modal-size-sm';
      case ModalSize.Medium:
        return 'modal-size-md';
      case ModalSize.Large:
        return 'modal-size-lg';
      case ModalSize.XLarge:
        return 'modal-size-xl';
      case ModalSize.XXLarge:
        return 'modal-size-xxl';
      default:
        return 'modal-size-lg';
    }
  }
}
