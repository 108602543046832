<div class="calendar-header">
  <span>
    @if (hasBeforeMonth) {
      <button (click)="previousClicked()" class="btn btn-icon-only">
        <svg-icon key="arrow-button-left" size="xs"></svg-icon>
      </button>
    }
  </span>
  <span (click)="viewChangedHandler()" class="calendar-header-label"
    >{{ periodLabel }}
    <i class="chevron">
      @if (currentView === CalendarView.Year) {
        <svg-icon key="chevron-up" size="md"></svg-icon>
      } @else if (currentView === CalendarView.Month) {
        <svg-icon key="chevron-down" size="md"></svg-icon>
      }
    </i>
  </span>
  <span>
    @if (hasNextMonth) {
      <button (click)="nextClicked()" class="btn btn-icon-only">
        <svg-icon key="arrow-button-right" size="xs"></svg-icon>
      </button>
    }
  </span>
</div>
