<div class="p-12 panel-wrapper">
  <div class="m-b-8">
    <svg-icon class="color-error m-r-8" key="lock-closed" size="md" />
    <span class="color-red-dark">{{ message }}</span>
  </div>
  <button class="btn btn-primitive opaque m-l-24" (click)="buttonClick.emit()">
    <svg-icon class="color-green-dark m-r-8" key="lock-open" size="md" />
    Remove from quarantine
  </button>
</div>
