<div class="pointee-metric">
  <div class="pointee-metric-content">
    @if (metricType === metricTypeEnum.Pointer) {
      <div class="metric-pointer">
        <span class="pointee-colored-circle" [ngStyle]="{ 'background-color': pointerColor }"></span>
      </div>
    }

    <div class="metric-main-content">
      <div class="pointee-metric-label">
        <span [tooltip]="label" [options]="ellipsisTooltipOptions" (mouseover)="onMouseOver($event)"> {{ label }} </span>
        @if (labelInfoTooltip && displayInfoTooltip) {
          <shared-info-icon [infoIconKey]="labelInfoIcon" [color]="labelInfoIconColor" [tooltip]="labelInfoTooltip"></shared-info-icon>
        }
      </div>
      <div class="pointee-metric-value">
        @if (marking) {
          <div class="metric-icon" shared-variant-marking [marking]="marking"></div>
        }

        <div class="value">
          @if (metricType === metricTypeEnum.Percentage && !isValueInvalid) {
            @if (!hasException) {
              <shared-performance-pie [arcs]="pieData ?? getDefaultPieData()" [width]="24" class="metric-percentage-graphic"></shared-performance-pie>
            } @else {
              <shared-performance-pie [arcs]="pieData ?? getDefaultPieData()" [width]="24" [colors]="getPieChartColorsForException()" class="metric-percentage-graphic"></shared-performance-pie>
            }
          }
          @if (!isValueInvalid) {
            @if (showWarning) {
              <svg-icon
                key="warning"
                class="m-r-8 color-warning-icon"
                [class.cursor-pointer]="isWarningClickable"
                [tooltip]="warningTooltip"
                (click)="isWarningClickable ? warningClick.emit() : null"
              ></svg-icon>
            }
            <span [tooltip]="tooltip" [display]="displayTooltip">{{ value | format: formatType : false : false : unit }}</span>
            @if (params?.length === 1) {
              <span class="value-params">({{ params[0] | format: paramsType }})</span>
            } @else if (params?.length === 2) {
              <span class="value-params">({{ params[0] | format: paramsType }} of {{ params[1] | format: paramsType }})</span>
            }
          }
          @if (isValueInvalid) {
            <span [tooltip]="invalidValueTooltip ?? TextConstants.notAvailable" [display]="displayTooltip">{{ TextConstants.notAvailable }}</span>
          }
        </div>
        @if (trendValue && !isValueInvalid) {
          <shared-visual-trend class="metric-trend" [trend]="trendValue" [trendType]="trendType" [isPositive]="trendIsPositive" [tooltip]="trendTooltip"></shared-visual-trend>
        }
      </div>
    </div>
  </div>
</div>
