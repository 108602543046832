import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-input-icon',
  templateUrl: './input-icon.component.html',
  styleUrls: ['./input-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputWithIconComponent implements AfterViewInit {
  @Input() value = '';
  @Input() icon = '';
  @Input() placeholder: string;
  @Input() hasFocus = true;
  @Output() submitValue = new EventEmitter<string>();
  @Output() clear = new EventEmitter();

  @ViewChild('iconInput', { static: true }) iconInput: ElementRef;
  @HostListener('document:keydown.enter', ['$event']) onEnterHandler(event: KeyboardEvent) {
    this.submitValue.next(this.value);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.onClear(event);
  }

  ngAfterViewInit(): void {
    if (this.hasFocus) {
      this.setFocus();
    }
  }

  private setFocus(): void {
    if (this.iconInput && this.hasFocus) {
      const inputElm = this.iconInput.nativeElement as HTMLInputElement;
      inputElm.focus();
      inputElm.select();
    }
  }

  onClear(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.value = '';
    this.clear.emit();
  }
}
