<mat-select
  #regionSelection
  placeholder="{{ multiple ? 'Select countries' : 'Select a country' }}"
  [multiple]="multiple"
  [disabled]="disabled"
  [(ngModel)]="selectionModel"
  (opened)="markAsTouched()"
  (closed)="closed.emit()"
  (blur)="onBlur()"
  [class.mat-mdc-select-invalid]="isInvalid"
>
  <mat-select-trigger>
    <div class="selected-content" [tooltip]="selectedRegionNamesTooltip" tooltipIfEllipsis>
      @if (multiple) {
        @for (item of selectionModel; track item; let indexOfelement = $index) {
          <span class="selected-item"
            ><i class="fi fi-{{ item.toLowerCase() }}"></i>{{ getRegionName(item) }}
            @if (indexOfelement < selectionModel.length - 1) {
              <span>,</span>
            }
          </span>
        }
      } @else {
        @if (selectionModel) {
          <i class="fi fi-{{ selectionModel.toLocaleString().toLocaleLowerCase() }}"></i>
        }
        {{ getRegionName(selectionModel.toLocaleString()) }}
      }
    </div>
  </mat-select-trigger>
  @if (searchEnabled) {
    <mat-option>
      <shared-select-search [(ngModel)]="filter"></shared-select-search>
    </mat-option>
  }
  @for (region of filteredRegions; track region) {
    <mat-option [value]="region.isoRegionName"><i class="fi fi-{{ region.isoRegionName.toLowerCase() }}"></i>{{ region.englishName }}</mat-option>
  }
</mat-select>
