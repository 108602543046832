<div class="zoom-container" [class.vertical]="isVertical">
  @if (filterExtraButtons(ZoomComponentButtonPosition.Start); as buttons) {
    @for (btn of buttons; track btn; let i = $index) {
      <button
        class="btn btn-icon"
        [class.disabled]="isButtonDisabled(btn)"
        [attr.id]="btn.id"
        [disabled]="isButtonDisabled(btn)"
        [style.order]="-1000 + i"
        [tooltip]="btn.tooltip"
        [placement]="isVertical ? 'left' : 'top'"
        (click)="btn.action()"
        >
        <svg-icon [key]="btn.icon"></svg-icon>
      </button>
    }
    <hr [style.order]="-1" />
  }

  @for (btn of defaultButtonsDefinition; track btn) {
    @if (defaultButtons.includes(btn.data)) {
      <button
        class="btn btn-icon"
        [class.disabled]="isButtonDisabled(btn)"
        [attr.id]="btn.id"
        [disabled]="isButtonDisabled(btn)"
        [style.order]="defaultButtons.indexOf(btn.data)"
        [tooltip]="btn.tooltip"
        [placement]="isVertical ? 'left' : 'top'"
        (click)="btn.action()"
        >
        @if (btn.data === ZoomDefaultButton.ResetZoom) {
          {{ zoom | format:FormatType.Percentage }}
        }
        @if (btn.data !== ZoomDefaultButton.ResetZoom) {
          <svg-icon [key]="btn.icon"></svg-icon>
        }
      </button>
    }
  }

  @if (filterExtraButtons(ZoomComponentButtonPosition.End); as buttons) {
    <hr [style.order]="1000" />
    @for (btn of buttons; track btn; let i = $index) {
      <button
        class="btn btn-icon"
        [class.disabled]="isButtonDisabled(btn)"
        [attr.id]="btn.id"
        [disabled]="isButtonDisabled(btn)"
        [style.order]="1000 + i"
        [tooltip]="btn.tooltip"
        [placement]="isVertical ? 'left' : 'top'"
        (click)="btn.action()"
        >
        <svg-icon [key]="btn.icon"></svg-icon>
      </button>
    }
  }
</div>
