import { Injectable } from '@angular/core';
import { Graphviz } from '@hpcc-js/wasm-graphviz';
import { nodeConfig } from '../types/constants';
import { Edge } from '../types/edge.type';
import { Node } from '../types/node.type';
import { Positions, NodePosition, EdgePosition, ClusterPosition, ClusterStartEndPosition } from '../types/positions.type';
import { LoggingService } from './logging.service';

class Graph {
  bb: string;
  bbParsed: number[];
  objects: GraphObject[];
  edges: GraphEdge[];
}

class GraphObject {
  _gvid: number;
  name: string;
  pos: string;
  posParsed: number[];
  bb: string;
  bbParsed: number[];
}

class GraphEdge {
  tail: number;
  head: number;
  pos: string;
  posParsed: number[][];
  lp: string;
  lpParsed: number[];
}

@Injectable({ providedIn: 'root' })
export class GraphvizService {
  private dpi = 72;
  private margin = 16;
  ranks = new Map<Node, number>();
  substituteNodes = new Map<number, number>();
  visibleNodes = new Set<number>();
  levelNodes: Node[] = [];

  constructor(private loggingService: LoggingService) {}

  async calculatePositions(rootNodes: Node[], nodes: Node[], edges: Edge[]): Promise<Positions> {
    if (nodes?.length === 0 && edges?.length === 0) {
      return new Positions();
    }

    this.visibleNodes = new Set(nodes.filter(n => n.isVisible).map(n => n.id));
    const lines: string[] = [];
    const input = this.createGraphvizInput(lines, rootNodes, edges);
    const graphviz = await Graphviz.load();
    const output = graphviz.layout(input, 'json', 'dot');
    return this.parseJsonOutput(output, nodes, edges);
  }

  private createGraphvizInput(lines: string[], levelNodes: Node[], edges: Edge[]): string {
    // level nodes in all variants
    const levelNodesSet = new Set(levelNodes);
    lines.push('strict digraph {');
    lines.push('  newrank=true;');
    lines.push(`  node[shape=rectangle, width=${this.toInches(nodeConfig.nodeWidth + this.margin)}, height=${this.toInches(nodeConfig.nodeHeight + this.margin)}];`);
    lines.push('  edge[arrowhead=none, arrowtail=none, minlen=1, fontsize=16, style=solid];');

    for (const n of levelNodes) {
      if (n.isExpanded) {
        this.drawCluster(lines, n, levelNodesSet);
      } else {
        this.appendNode(lines, n, levelNodesSet);
      }
    }

    const levelEdges = edges.filter(e => e.isVisible && levelNodesSet.has(e.startNode) && levelNodesSet.has(e.endNode));
    for (const edge of levelEdges) {
      let from = edge.startNode.isExpanded ? (edge.from === edge.endNode.parentNodeId ? `start${edge.from}` : `end${edge.from}`) : edge.from.toString();
      let to = edge.endNode.isExpanded ? (edge.to === edge.startNode.parentNodeId ? `end${edge.to}` : `start${edge.to}`) : edge.to.toString();
      if (to.startsWith('start') && edge.endNode.children.length === 1 && edge.endNode.children[0].children.length > 0 && !edge.endNode.isVirtual) {
        this.substituteNodes.set(edge.to, this.getFirstRelevantClusterNode(edge.endNode).id);
        to = `start${this.substituteNodes.get(edge.to)}`;
      }
      if (from.startsWith('end') && edge.startNode.children.length === 1 && edge.startNode.children[0].children.length > 0 && !edge.startNode.isVirtual) {
        this.substituteNodes.set(edge.from, this.getFirstRelevantClusterNode(edge.startNode).id);
        from = `end${this.substituteNodes.get(edge.from)}`;
      }
      if (
        (edge.startNode.children.length !== 1 || edge.startNode.children[0].children.length === 0 || edge.to !== edge.startNode.children[0].id) &&
        (edge.endNode.children.length !== 1 || edge.endNode.children[0].children.length === 0 || edge.from !== edge.endNode.children[0].id)
      ) {
        lines.push(`  ${from} -> ${to}[label=_${edge.statistics.casesCount}_]`);
      }
    }
    lines.push('}');
    return lines.join('\n');
  }

  private drawCluster(lines: string[], cluster: Node, levelNodesSet: Set<Node>) {
    levelNodesSet.add(cluster);
    lines.push(`subgraph cluster${cluster.id} {`);
    lines.push(`margin=${2 * this.margin}`);
    if (cluster.children.length > 1 || cluster.children[0].children == null || cluster.children[0].children.length === 0) {
      lines.push(`start${cluster.id} [shape="point", width=${this.toInches(12 + this.margin)}, label="", fontsize=0]`);
      lines.push(`end${cluster.id} [shape="point", width=${this.toInches(12 + this.margin)}, label="", fontsize=0]`);
    }

    for (const n of cluster.children) {
      if (!this.visibleNodes.has(n.id)) {
        continue;
      }
      if (n.isExpanded) {
        this.drawCluster(lines, n, levelNodesSet);
      } else {
        this.appendNode(lines, n, levelNodesSet);
      }
    }
    lines.push('}\n');
  }

  private getFirstRelevantClusterNode(node: Node): Node {
    if (node.children == null || node.children.length > 1 || (node.children.length === 1 && node.children[0].children?.length === 0)) {
      return node;
    } else if (node.children.length === 1 && node.children[0]?.id) {
      return this.getFirstRelevantClusterNode(node.children[0]);
    } else {
      return node;
    }
  }

  private appendNode(lines: string[], node: Node, levelNodesSet: Set<Node>) {
    if (!node.isVisible) {
      return;
    }
    levelNodesSet.add(node);
    lines.push(`${node.id}${node.isStartEnd ? '[shape=Mcircle, width=1]' : ''}`);
  }

  private toInches(pixels: number): number {
    return pixels / this.dpi;
  }

  private parseJsonOutput(output: string, nodes: Node[], edges: Edge[]): Positions {
    const graph = this.parseGraph(output);
    const positions = new Positions();
    positions.width = graph.bbParsed[2] + this.margin;
    positions.height = graph.bbParsed[3] + this.margin;

    const idToNodeId: Record<number, number> = {};
    for (const o of graph.objects) {
      if (o.name.startsWith('cluster')) {
        const id = Number(o.name.substring(7));
        const node = nodes.find(n => n.id === id);
        const parent = nodes.find(n => n.id === node.parentNodeId);
        const x1 = o.bbParsed[0] + 2 * this.margin;
        const y1 = positions.height - o.bbParsed[1] - 2 * this.margin;
        const x2 = o.bbParsed[2];
        const y2 = positions.height - o.bbParsed[3];
        positions.clusters.push(new ClusterPosition(x1, y1, x2, y2, node, parent?.children?.length > 1 || parent == null));
      } else if (o.name.startsWith('start') || o.name.startsWith('end')) {
        const id = Number(o.name.substring(o.name.startsWith('start') ? 5 : 3));
        const node = nodes.find(n => n.id === id);
        idToNodeId[o._gvid] = node.id;
        const x = o.posParsed[0] + this.margin;
        const y = positions.height - o.posParsed[1] - this.margin;
        positions.clusterStartEnds.push(new ClusterStartEndPosition(x, y, o.name.startsWith('end'), node));
      } else if (!o.name.startsWith('%')) {
        const node = nodes.find(n => n.id === Number(o.name));
        const x = o.posParsed[0] + this.margin;
        const y = positions.height - o.posParsed[1] - this.margin;
        if (node == null) {
          this.loggingService.logError(`Can't find node ${o.name}`);
        } else {
          idToNodeId[o._gvid] = node.id;
          positions.nodes.push(new NodePosition(x, y, node));
        }
      }
    }

    graph.edges?.forEach(e => {
      const path: number[] = [];
      for (let i = 0; i < e.posParsed.length; i++) {
        const point = e.posParsed[i];
        path.push(point[0] + this.margin);
        path.push(positions.height - point[1] - this.margin);
      }
      let edge = edges.find(ee => ee.from === idToNodeId[e.tail] && ee.to === idToNodeId[e.head]);

      if (!edge && ([...this.substituteNodes.values()].includes(idToNodeId[e.head]) || [...this.substituteNodes.values()].includes(idToNodeId[e.tail]))) {
        const eTails = [...this.substituteNodes.keys()].filter(k => this.substituteNodes.get(k) === idToNodeId[e.tail]);
        const eTail = eTails && eTails.length > 0 ? Math.min(...eTails) : idToNodeId[e.tail];
        const eHeads = [...this.substituteNodes.keys()].filter(k => this.substituteNodes.get(k) === idToNodeId[e.head]);
        const eHead = eHeads && eHeads.length > 0 ? Math.min(...eHeads) : idToNodeId[e.head];

        edge = edges.find(ee => ee.from === eTail && ee.to === eHead);
        if (!edge) {
          edge = edges.find(ee => ee.from === idToNodeId[e.tail] && ee.to === eHead);
        }
        if (!edge) {
          edge = edges.find(ee => ee.from === eTail && ee.to === idToNodeId[e.head]);
        }
        if (!edge) {
          edge = edges.find(ee => ee.from === idToNodeId[e.tail] && ee.to === idToNodeId[e.head]);
        }
      }
      if (edge) {
        const edgePosition = positions.edges.find(ee => ee.edge === edge);
        if (edgePosition) {
          edgePosition.path = path;
          edgePosition.calculatePathDefinition();
        } else {
          positions.edges.push(new EdgePosition(path, edge));
        }
      } else {
        this.loggingService.logWarning(`Can't find edge between ${nodes.find(n => n.id === idToNodeId[e.tail])?.id || e.tail} and ${nodes.find(n => n.id === idToNodeId[e.head])?.id || e.head}`);
      }
    });
    return positions;
  }

  private parseGraph(json: string): Graph {
    const graph = JSON.parse(json) as Graph;
    const parsePos = (s: string) => (s != null ? s.split(',').map(n => parseFloat(n)) : null);
    graph.bbParsed = parsePos(graph.bb);
    graph.objects?.forEach(o => {
      o.posParsed = parsePos(o.pos);
      o.bbParsed = parsePos(o.bb);
    });
    graph.edges?.forEach(e => {
      e.posParsed = e.pos.split(' ').map(p => parsePos(p));
      e.lpParsed = parsePos(e.lp);
    });
    return graph;
  }
}
