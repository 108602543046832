import { Edge } from './edge.type';
import { Node } from './node.type';

export class Positions {
  width: number;
  height: number;
  nodes: NodePosition[] = [];
  clusters: ClusterPosition[] = [];
  clusterStartEnds: ClusterStartEndPosition[] = [];
  edges: EdgePosition[] = [];
}

export class NodePosition {
  constructor(
    public x: number,
    public y: number,
    public node: Node
  ) {}
}

export class ClusterPosition {
  width: number;
  height: number;
  constructor(
    public x1: number,
    public y1: number,
    public x2: number,
    public y2: number,
    public node: Node,
    public hasSiblings = true
  ) {
    this.width = x2 - x1;
    this.height = y1 - y2;
  }
}

export class ClusterStartEndPosition {
  constructor(
    public x: number,
    public y: number,
    public isEnd: boolean,
    public parent: Node
  ) {}
}

export class EdgePosition {
  pathDefinition: string;
  constructor(
    public path: number[],
    public edge: Edge
  ) {
    this.calculatePathDefinition();
  }

  calculatePathDefinition() {
    const pathParts = [];
    for (let i = 0; i < this.path.length; i += 2) {
      const prefix = i === 0 ? 'M' : i === 2 ? 'C' : ' ';
      pathParts.push(`${prefix}${this.path[i]},${this.path[i + 1] - 1.5}`);
    }
    this.pathDefinition = pathParts.join('');
  }
}
