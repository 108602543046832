import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'shared-quarantined-info-panel',
  templateUrl: './quarantined-info-panel.component.html',
  styleUrl: './quarantined-info-panel.component.scss',
})
export class QuarantinedInfoPanelComponent {
  @Input() message: string;
  @Output() buttonClick = new EventEmitter<void>();
}
