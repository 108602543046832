import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Subscription } from 'rxjs';
import { ProcessingItemError } from 'src/app/layout/upload-processing/processing-item-error.enum';
import { ProcessingItemStatus } from '../../../../layout/upload-processing/processing-item-state.enum';
import { UploadingItem } from '../../../../layout/upload-processing/uploading-item.type';
import { BaseModalDialogDirective } from '../../../directives/base-modal-dialog.directive';
import { FormatType } from '../../../enums/format-type.enum';
import { UploadItemType } from '../../../enums/upload-item-type.enum';
import { ApiService } from '../../../services/api/api.service';
import { AppConfigurationService } from '../../../services/app-configuration.service';
import { LoggingService } from '../../../services/logging.service';
import { PopupService } from '../../../services/popup.service';
import { UploadService } from '../../../services/upload.service';
import { ModalCloseType } from '../../modal/modal-close-type.enum';
import { FeatureFlags } from "src/app/shared/enums/generated.enums";
import { AuthService } from "src/app/shared/services/auth.service";

@Component({
  selector: 'shared-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
})
export class ContactUsModalComponent extends BaseModalDialogDirective implements OnInit, OnDestroy {
  contactCareForm = this.formBuilder.group({
    message: ['', [Validators.required, Validators.minLength(2)]],
    attachFiles: [false],
  });

  readonly FormatType = FormatType;
  readonly ProcessingItemStatus = ProcessingItemStatus;
  submitted: boolean;
  attachmentFiles: UploadingItem[] = [];
  appVersion: string;
  documentationUrl: string;

  get isDocumentationEnabled(): boolean {
    return this.authService.hasFeatureFlag(FeatureFlags.UserDocumentation);
  }

  get closeType(): ModalCloseType {
    return ModalCloseType.Safe;
  }

  get isFormInvalid(): boolean {
    const shouldCheckFiles = this.contactCareForm.controls.attachFiles.value && this.attachmentFiles.length > 0;
    if (!shouldCheckFiles) {
      return this.contactCareForm.invalid || this.contactCareForm.pristine;
    }

    const isSomeFileUploadingOrError = this.attachmentFiles.some(x => x.isUploading || x.isError);
    return isSomeFileUploadingOrError;
  }

  get isOnPremise(): boolean {
    return this.appConfiguration.isOnPremise;
  }

  private _subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private popupService: PopupService,
    private loggingService: LoggingService,
    private uploadService: UploadService,
    private appConfiguration: AppConfigurationService,
    private authService: AuthService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this._subscriptions.add(
      this.uploadService.onUploadStarted(UploadItemType.CustomerFile).subscribe(item => {
        const uploadingItem = new UploadingItem(item.id, item.fileName);
        this.attachmentFiles.push(uploadingItem);
      }),
    );

    this._subscriptions.add(
      this.uploadService.onUploadProgress().subscribe(e => {
        const uploadingItem = this.attachmentFiles.find(x => x.id === e.item.id);
        uploadingItem?.onProgress(e.progress);
      }),
    );

    this._subscriptions.add(
      this.uploadService.onUploadFinished().subscribe(item => {
        const uploadingItem = this.attachmentFiles.find(x => x.id === item.id);
        uploadingItem?.onUploadFinished(item.data.fileId);
      }),
    );

    this._subscriptions.add(
      this.uploadService.onUploadError().subscribe(e => {
        const uploadingItem = this.attachmentFiles.find(x => x.id === e.item.id);
        uploadingItem?.onError(ProcessingItemError.UploadFailed);
      }),
    );

    this.appVersion = `${this.appConfiguration.clientVersion} / ${this.appConfiguration.serverVersion}`;
    this.documentationUrl = await this.apiService.getDocumentationUrl();
  }

  ngOnDestroy(): void {
    const filesToDelete = this.attachmentFiles.filter(x => x.uploadedFileId);
    const filesToCancel = this.attachmentFiles.filter(x => x.canCancel);

    filesToCancel.forEach(x => {
      try {
        this.uploadService.cancelUpload(x.id);
      } catch {
        // ignored
      }
    });

    filesToDelete.forEach(x => {
      try {
        this.apiService.deleteCustomerFile(x.uploadedFileId);
      } catch {
        // ignored
      }
    });

    this.attachmentFiles = [];

    this._subscriptions.unsubscribe();
  }

  async onSubmit(): Promise<void> {
    if (this.submitted || this.isFormInvalid) {
      return;
    }
    try {
      const files = this.attachmentFiles.map(x => x.uploadedFileId);
      await this.apiService.sendCustomerFeedbackMessage(this.contactCareForm.controls.message.value, files);
      this.attachmentFiles = [];
      this.submitted = true;
    } catch (error: any) {
      this.submitted = false;
      this.loggingService.logException(error);
      await this.popupService.showInfo('Your message could not be sent!', 'We are sorry');
    }
  }

  onFileDrop(files: NgxFileDropEntry[]): void {
    files.forEach(sf => (sf.fileEntry as FileSystemFileEntry).file(f => this.uploadService.uploadCustomerFile(f)));
  }

  cancelUpload(item: UploadingItem): void {
    this.removeItem(item);
    this.uploadService.cancelUpload(item.id);
  }

  async deleteFile(item: UploadingItem): Promise<void> {
    this.removeItem(item);
    if (item.uploadedFileId != null) {
      await this.apiService.deleteCustomerFile(item.uploadedFileId);
    }
  }

  private removeItem(item: UploadingItem): void {
    this.attachmentFiles = this.attachmentFiles.filter(x => x.id !== item.id);
  }
}
