import { FormatType } from '../../../enums/format-type.enum';

export enum BarChartOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface BarChartData {
  items: BarChartItem[];
  labels?: string[];
}

export interface BarChartItem {
  id: string;
  label: string;
  value?: number;
  lowLimit?: number;
  highLimit?: number;
}

export class BarChartConfiguration {
  title?: string;
  formatType: FormatType;
  color: string;
  unit?: string;
  threshold?: number;
  colorAboveThreshold?: string;
  maxBarThickness?: number;
  orientation?: BarChartOrientation;
  fixedLabelWidth?: boolean;
  minYAxisWidth?: number;
  min?: number;
  max?: number;
  showLegend?: boolean;
  dataLabels?: string[];
  tooltipFooter?: string;
}

export interface BarChartMarkedValue {
  value: number;
  color: string;
  hoverColor?: string;
  label?: string;
}
