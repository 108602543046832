import { Injectable } from '@angular/core';
import { SubPageType } from '../enums/page-type.enum';
import { DateHelper, DateRangeFormat } from '../helpers/date.helper';
import { SetHelper } from '../helpers/set.helper';
import { TextHelper } from '../helpers/text.helper';
import { BreadcrumbItem } from '../types/breadcrumb-item.type';
import { Data } from '../types/data.type';
import { ProcessBasicInfo } from '../types/process-basic-Info.type';
import { NavigationService } from './navigation.service';
import { SelectionService } from './selection.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  breadcrumbItems: BreadcrumbItem[] = [];

  get displaySubprocessPath(): boolean {
    return this.navigationService.isOnSubPage(SubPageType.ProcessMap) || this.navigationService.isOnSubPage(SubPageType.VariantAnalysis);
  }

  constructor(private selectionService: SelectionService, private navigationService: NavigationService, private data: Data) {}

  async createBreadcrumbForAnalysis(): Promise<void> {
    this.clear();

    if (this.selectionService.selectedProcess == null) {
      return;
    }
    const processes: ProcessBasicInfo[] = [];
    let selectedProcess = this.selectionService.selectedProcess;

    while (selectedProcess != null) {
      processes.unshift(selectedProcess);
      selectedProcess = selectedProcess.parent;
    }

    processes.forEach((p, i) => {
      const route = this.navigationService.getCurrentPageRoute(p.id);
      const label = p.from != null ? TextHelper.convertSpacesToNbsp(`Analysis of ${p.label} (${DateHelper.formatDateRange(p.from, p.to, DateRangeFormat.DateOnly)})`) : p.label;
      this.addBreadcrumbItem(route, label);
    });

    if (this.breadcrumbItems.length > 4) {
      const tooltip = this.breadcrumbItems.map(b => b.label).join(' ><br/>');
      const breadcrumbItems = [this.breadcrumbItems[0]];
      breadcrumbItems.push(({ route: null, label: '...', tooltip } as BreadcrumbItem));
      breadcrumbItems.push(this.breadcrumbItems.at(-2));
      breadcrumbItems.push(this.breadcrumbItems.at(-1));
      this.breadcrumbItems = breadcrumbItems;
    }
    this.breadcrumbItems.at(-1).route = null;
  }

  private addBreadcrumbItem(route: string[], label: string) {
    this.breadcrumbItems.push({ route, label, tooltip: label });
  }

  public clear() {
    this.breadcrumbItems = [];
  }
}
