<form [formGroup]="timePickerForm" id="timePickerWrapper">
  <div class="time-input-controls" [class.disabled]="disabled" [class.ng-invalid]="isInvalid">
    <button type="button" class="btn" (click)="subTime()" [disabled]="time <= 0 || disabled" tabindex="-1"><svg-icon key="arrow-button-left" fontSize="11px"></svg-icon></button>
    <div id="time_input">
      <input type="text" name="hour" formControlName="hour" mask="Hh" (blur)="normalizeHours()" />:<input
      type="text"
      name="minute"
      formControlName="minute"
      mask="m0"
      (blur)="padInputValue(f.minute)"
      />
    </div>
    <button type="button" class="btn" (click)="addTime()" [disabled]="time >= maxDayTime || disabled" tabindex="-1">
      <svg-icon key="arrow-button-right" fontSize="11px"></svg-icon>
    </button>
  </div>

  @if (!isTime24h) {
    <div class="btn am-pm-toggle" [class.disabled]="disabled">
      <button class="am-pm-item" (click)="setTimeFormat('AM')" [class.selected]="timeFormat === 'AM'">AM</button>
      <button class="am-pm-item" (click)="setTimeFormat('PM')" [class.selected]="timeFormat === 'PM'">PM</button>
    </div>
  }
</form>
