import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Calendar } from '../../types/calendar/calendar.type';
import { CalendarEvent } from '../../types/calendar/calendar-event.type';
import { DateHelper } from '../../helpers/date.helper';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';

@Injectable({ providedIn: 'root' })
export class CalendarApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}`;
  }

  async getCalendars(): Promise<Calendar[]> {
    return await this.get<Calendar[]>('calendar');
  }

  async getEvents(calendarId: string, from: Date, to: Date): Promise<CalendarEvent[]> {
    const events = await this.get<CalendarEvent[]>(`calendar/${calendarId}/events/`, { from: DateHelper.formatApiUtcDateTime(from), to: DateHelper.formatApiUtcDateTime(to) });
    ApiConversionHelper.convertDates(events, ['start', 'end']);
    return events;
  }

  async createEvent(event: CalendarEvent): Promise<void> {
    await this.post('calendar-events', event);
  }

  async updateEvent(event: CalendarEvent, forDate: Date = null, fromDate: Date = null): Promise<void> {
    await this.put('calendar-events', event, {
      forDate: forDate ? DateHelper.formatApiUtcDateTime(forDate) : null,
      fromDate: fromDate ? DateHelper.formatApiUtcDateTime(fromDate) : null,
    });
  }

  async deleteEvent(eventId: string, forDate: Date = null, fromDate: Date = null): Promise<void> {
    await this.delete(`calendar-events/${eventId}`, {
      forDate: forDate ? DateHelper.formatApiUtcDateTime(forDate) : null,
      fromDate: fromDate ? DateHelper.formatApiUtcDateTime(fromDate) : null,
    });
  }
}
