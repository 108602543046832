<div class="form-field" [ngClass]="containerClass">
  <label>{{ label }}@if (labelTooltip) {
    <shared-info-icon [tooltip]="labelTooltip" class="p-l-8"></shared-info-icon>
  }</label>

  <div class="field-content">
    <ng-content></ng-content>

    @if (control && control.invalid && (control.dirty || control.touched)) {
      @if (control.errors?.required) {
        <mat-error>{{ label }} is required</mat-error>
      }
      @if (control.errors?.min) {
        <mat-error>Minimum value is {{ control.errors?.min.min }}</mat-error>
      }
      @if (control.errors?.max) {
        <mat-error>Maximum value is {{ control.errors?.max.max }}</mat-error>
      }
    }
  </div>
</div>
