import { Injectable } from '@angular/core';
import { filter, fromEvent, map } from 'rxjs';
import { PopupService } from './popup.service';

@Injectable({ providedIn: 'root' })
export class ClipboardService {
  pasted$ = fromEvent(document, 'paste').pipe(
    filter(ev => !(ev.target instanceof HTMLInputElement || ev.target instanceof HTMLTextAreaElement)),
    map(ev => (ev as ClipboardEvent)?.clipboardData),
  );

  get isReadSupported(): boolean {
    return navigator.clipboard?.readText != null;
  }

  get isWriteSupported(): boolean {
    return navigator.clipboard?.writeText != null;
  }

  constructor(private popupService: PopupService) {}

  async writeText(content: string, successMessage: string = null): Promise<void> {
    try {
      await navigator.clipboard.writeText(content);
      this.popupService.showSnackBar(successMessage ?? 'Copied to clipboard', 3000);
    } catch (error) {
      this.showClipboardError(error);
    }
  }

  async write(data: any, successMessage: string = null): Promise<void> {
    await this.writeText(JSON.stringify(data), successMessage);
  }

  async read<T>(converter?: (obj: T) => void): Promise<T> {
    if (!this.isReadSupported) {
      this.popupService.showSnackBar('Browser cannot access the clipboard.<br/>Use CTRL+V instead.', 3000);
      return null;
    }

    try {
      const content = await navigator.clipboard.readText();
      try {
        const result = JSON.parse(content) as T;
        converter?.(result);
        return result;
      } catch {
        return null;
      }
    } catch (error) {
      this.showClipboardError(error);
      return null;
    }
  }

  parseTextData<T>(data: DataTransfer, converter?: (obj: T) => void): T {
    try {
      const result = JSON.parse(data.getData('text/plain')) as T;
      converter?.(result);
      return result;
    } catch {
      return null;
    }
  }

  private showClipboardError(error: any): void {
    this.popupService.showSnackBar('Failed to access clipboard', 3000);
    console.error(error);
  }
}
