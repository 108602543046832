import { SelectFixedItem } from '../components/select-with-search/select-item.type';
import { DateRange } from '../types/date-range.type';
import { FilteringHelper } from './filtering.helper';

export enum FilterName {
  Queue = 'queue',
  Process = 'process',
  MonitoringFolder = 'monitoring-folder',
  Exception = 'exception',
  Resource = 'resource',
  TimePeriod = 'time-period',
  Domain = 'domain',
  Status = 'status',
  Tags = 'tags',
  Search = 'search',
  Impact = 'impact',
  Category = 'category',
  Feedback = 'feedback',
}

export type FilterValueType = string | DateRange;

export interface FilterItemValue {
  id: FilterValueType;
  label: string;
  count?: number;
  disabled?: boolean;
  type?: string;
  group?: string;
}

export interface FilterFixedValue {
  id: string;
  label: string;
  type: 'all' | 'explicit';
  count?: number;
  disabled?: boolean;
}

export enum FilterItemType {
  Simple,
  Select,
  MultiSelect,
}

export interface BaseFilterItem {
  type: FilterItemType;
  name: string;
  fixedItems?: FilterFixedValue[];
  queryParamName?: string;
  source?: string;
}

export interface SimpleFilterItem extends BaseFilterItem {
  type: FilterItemType.Simple;
  value?: FilterItemValue;
}

export interface SelectFilterItem extends BaseFilterItem {
  type: FilterItemType.Select;
  value?: string;
  items: FilterItemValue[];
  placeholder?: string;
}

export interface MultiSelectFilterItem extends BaseFilterItem {
  type: FilterItemType.MultiSelect;
  value?: string[];
  items: FilterItemValue[];
  placeholder?: string;
  clearFilterIfAllSelected?: boolean;
}

export type FilterItem = SimpleFilterItem | SelectFilterItem | MultiSelectFilterItem;

export class FilterDefinition {
  constructor(public filters: FilterItem[]) {}

  isFilterActive(name: string): boolean {
    return this.filters.find(filter => filter.name === name)?.value != null;
  }

  isAnyFilterActive(names: string[]): boolean {
    return names.some(name => this.isFilterActive(name));
  }

  getFilterValue<T extends string | string[] | DateRange | null>(name: string, ignoredSource: string[] = []): T {
    const filter = this.filters.find(f => f.name === name);
    if (filter == null || ignoredSource.includes(filter.source)) {
      return null;
    }

    if (filter.type === FilterItemType.Simple) {
      return filter.value?.id as T;
    }

    return filter.value as T;
  }

  getFilterValues(names: string[], ignoredSource: string[] = []): any {
    return names.reduce((acc, name) => ({ ...acc, [name]: this.getFilterValue(name, ignoredSource) }), {});
  }

  getFilter(name: string): FilterItem {
    const filter = this.filters.find(f => f.name === name);
    if (filter == null) {
      throw Error(`Filter "${name}" not found`);
    }
    return filter;
  }

  static createSelect(name: string, items: FilterItemValue[], allItemsLabel: string, value: any, queryParamName: string = null, placeholder: string = null): FilterItem {
    return this.createFilterItem(FilterItemType.Select, name, items, allItemsLabel, value, queryParamName, placeholder);
  }

  static createMultiSelect(name: string, items: FilterItemValue[], allItemsLabel: string, value: any, queryParamName: string = null, placeholder: string = null): FilterItem {
    return this.createFilterItem(FilterItemType.MultiSelect, name, items, allItemsLabel, value, queryParamName, placeholder);
  }

  private static createFilterItem(
    type: FilterItemType,
    name: string,
    items: FilterItemValue[],
    allItemsLabel: string,
    value: any,
    queryParamName: string = null,
    placeholder: string = null
  ): FilterItem {
    const fixedItem: SelectFixedItem = { id: FilteringHelper.AllItemsId, label: allItemsLabel, type: 'all', isFixed: true };
    return { type, name, items, fixedItems: [fixedItem], value, queryParamName, placeholder, clearFilterIfAllSelected: true };
  }
}
