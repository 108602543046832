<div class="form-control chip-input" [class.disabled]="disabled" (keydown)="onKeyDown($event)">
  <mat-chip-grid #chipList aria-label="chip selection" class="chip-content">
    @for (chip of chips; track chip; let i = $index) {
      <mat-chip [selectable]="selectable" [removable]="removable" (removed)="remove(chip)">
        {{ chip }}
        @if (removable) {
          <svg-icon key="close" size="xs" matChipRemove></svg-icon>
        }
      </mat-chip>
    }
    <input
      #chipInput
      [formControl]="chipCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      [placeholder]="maxCount && maxCount <= chips.length ? '' : placeholder ? 'Add ' + placeholder + '...' : ''"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="chip-autocomplete">
    <mat-option *ngFor="let chip of filteredChips | async" [value]="chip">
      {{ chip }}
    </mat-option>
  </mat-autocomplete>
  @if (maxCount) {
    <mat-error *ngIf="chips.length > maxCount">You can only add {{ maxCount }} {{ maxCount > 1 ? placeholder + 's' : placeholder }}</mat-error>
  }
</div>
