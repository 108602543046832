import { SelectFixedItem, SelectItem } from './select-item.type';

export type Selection = SelectItem | SelectItem[];

export class SelectionHelper {
  static createFixedItem(id: string, label: string, type: 'all' | 'explicit'): SelectFixedItem {
    return { id, label, type, isFixed: true };
  }

  static allSelected(selection: Selection): boolean {
    return Array.isArray(selection) && this.getFixedItem(selection, 'all') != null;
  }

  static getItemIds(selection: Selection): string[] {
    return Array.isArray(selection) ? selection.filter(i => i?.isFixed != true).map(i => i.id) : [];
  }

  static getFixedItem(selectedItems: SelectItem[], type: 'all' | 'explicit'): SelectFixedItem {
    return selectedItems.filter(i => i?.isFixed).map(i => i as SelectFixedItem).find(i => i.type === type);
  }
}
