import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { FilterDefinition, FilterItem, FilterItemType } from '../../filtering/filter-definition.type';
import { FilterSelectionMap, FilteringHelper } from '../../filtering/filtering.helper';
import { FilteringService } from '../../filtering/filtering.service';
import { SelectItem } from '../select-with-search/select-item.type';
import { SelectionHelper } from '../select-with-search/selection';

@Component({
  selector: 'shared-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements OnInit {
  @Input() set items(value: FilterItem[]) {
    if (value == null) {
      return;
    }

    this.filteringService.initializeFilters(value);
  }

  @Output() filterChanged = new EventEmitter<FilterDefinition>();

  readonly FilterItemType = FilterItemType;
  filterDefinition$: Observable<FilterDefinition>;
  filterSelectionMap: FilterSelectionMap = new Map();

  constructor(private filteringService: FilteringService) {}

  ngOnInit(): void {
    this.filterDefinition$ = this.filteringService.filterDefinitionChanged$().pipe(
      tap(f => (this.filterSelectionMap = FilteringHelper.createFilterSelectionMap(f))),
      tap(f => this.filterChanged.emit(f)),
    );
  }

  async onFilterValueChanged(selection: SelectItem, filter: FilterItem): Promise<void> {
    if (filter.type !== FilterItemType.Select) {
      return;
    }

    if (selection.id === FilteringHelper.AllItemsId) {
      await this.filteringService.clearFilter(filter.name);
      return;
    }

    await this.filteringService.selectFilterValue(filter.name, selection.id);
  }

  async onFilterClosed(selection: SelectItem[], filter: FilterItem): Promise<void> {
    if (filter.type !== FilterItemType.MultiSelect) {
      return;
    }

    if (filter.clearFilterIfAllSelected && SelectionHelper.allSelected(selection)) {
      await this.filteringService.clearFilter(filter.name);
      return;
    }

    await this.filteringService.selectFilterValue(
      filter.name,
      selection.map(i => i.id),
    );
  }
}
