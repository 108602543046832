import { EdgeStatistics } from './edge-statistics.type';
import { NodeStatistics } from './node-statistics.type';

export class VariantStatistics {
  logsCount: number;
  logsCountPercentage: number;
  casesCount: number;
  casesCountPercentage: number;
  casesCountHistory: number[];
  firstOccurrence: Date;
  lastOccurrence: Date;
  meanDuration: number;
  meanDurationHistory: number[];
  medianDuration: number;
  minDuration: number;
  maxDuration: number;
  averageCosts: number;
  averageCostsHistory: number[];
  monthCosts: number;
  yearCosts: number;
  monthCasesCount: number;
  yearCasesCount: number;
  nodesStatistics: Record<number, NodeStatistics>;
  edgesStatistics: Record<string, EdgeStatistics>;

  totalDurationHistory: number[];

  static getEmptyStatistics(): VariantStatistics {
    return {
      logsCount: 0,
      logsCountPercentage: 0,
      casesCount: 0,
      casesCountPercentage: 0,
      casesCountHistory: [],
      firstOccurrence: null,
      lastOccurrence: null,
      meanDuration: 0,
      meanDurationHistory: [],
      medianDuration: 0,
      minDuration: 0,
      maxDuration: 0,
      averageCosts: 0,
      averageCostsHistory: [],
      monthCosts: 0,
      yearCosts: 0,
      monthCasesCount: 0,
      yearCasesCount: 0,
      nodesStatistics: {},
      edgesStatistics: {},
      totalDurationHistory: [],
    };
  }
}
