@if (estimationChanged && enteredCount != null) {
  <div class="estimation-notification">
    <p>
      {{ prefix }} estimates are based on&nbsp;a&nbsp;plan to process <b>{{ enteredCount | format: FormatType.Number:null:true }} {{ taskTypeLabel }} a&nbsp;{{ interval }}</b>
      @if (!isInMasterProcess) {
        in <button class="btn btn-link inline" (click)="goToMasterProcess();">{{ masterProcessLabel }}</button>
      }
      <span class="additional"> (instead of&nbsp;the&nbsp;original estimate of&nbsp;{{ calculatedCount | format: FormatType.Number:null:true }} {{ taskTypeLabel }} a&nbsp;{{ interval }}). </span>
    </p>
  </div>
}
