import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionableInsightStatus, ActionableInsightCategory, Platform, LoginType } from '../enums/generated.enums';
import { OverviewChartType } from '../enums/overview-chart-type.enum';
import { SubPageType } from '../enums/page-type.enum';
import { ProcessMapMetric } from '../enums/process-map-metric.enum';
import { ProcessPerformanceChartType } from '../enums/process-performance-chart-type.enum';
import { ScheduleType } from '../enums/schedule-type.enum';
import { DateHelper } from '../helpers/date.helper';
import { DashboardConfiguration } from '../types/dashboard-configuration.type';
import { DateRangeScope } from '../types/date-range-scope.type';
import { DateRange } from '../types/date-range.type';
import { LocalStorageData, LocalUserStorageData } from '../types/storage/local-storage-data.type';
import { MonitoringSettings } from '../types/storage/monitoring.settings.type';
import { SessionStorageData } from '../types/storage/storage-data.type';
import { FiltersSettings } from './../types/storage/filters-settings.type';
import { IncidentsFilters } from '../types/storage/incidents-filters.type';
import { ComparerType } from 'src/app/modules/reporting/process-reporting/process-group-compaper/process-group-comparer.type';

@Injectable({ providedIn: 'root' })
export class StorageService {
  private storageKeys = {
    sessionData: 'storage-pointee',
    localData: 'storage-pointee',
    localUserData: 'user-storage-pointee',
  };

  private sessionData: SessionStorageData;
  private localUserData: LocalUserStorageData;
  private localData: LocalStorageData;
  private processMapMetrics: Set<ProcessMapMetric>;
  private quantityMetrics = [ProcessMapMetric.LogsCount, ProcessMapMetric.CasesCount, ProcessMapMetric.CasesCountPercentage];
  private durationMetrics = [ProcessMapMetric.MeanDuration, ProcessMapMetric.MeanDurationPercentage];
  private priceMetrics = [ProcessMapMetric.AveragePrice, ProcessMapMetric.AveragePricePercentage, ProcessMapMetric.TotalYearCosts];

  processMapMetricsChanged = new Subject<string>();
  get globalDateRange(): DateRange {
    return this.getDateRange(DateRangeScope.Global);
  }

  set globalDateRange(dateRange: DateRange) {
    this.setDateRange(DateRangeScope.Global, dateRange);
  }

  get monitoringSettings(): MonitoringSettings {
    return this.sessionData.monitoringSettings;
  }

  set monitoringSettings(value: MonitoringSettings) {
    this.sessionData.monitoringSettings = value;
    this.saveSessionData();
  }

  updateMonitoringSettings(updateFn: (settings: MonitoringSettings) => void): void {
    updateFn(this.sessionData.monitoringSettings);
    this.saveSessionData();
  }

  get eventLogFilters(): FiltersSettings {
    return this.sessionData.eventLogFilters;
  }

  set eventLogFilters(filters: FiltersSettings) {
    this.sessionData.eventLogFilters = filters;
    this.saveSessionData();
  }

  get incidentsFilters(): IncidentsFilters {
    return this.sessionData.incidentsFilters;
  }

  set incidentsFilters(filters: IncidentsFilters) {
    this.sessionData.incidentsFilters = filters;
    this.saveSessionData();
  }

  get activeUtilizationSubPage(): SubPageType.Licenses | SubPageType.Resources | null {
    return this.sessionData.activeUtilizationSubPage;
  }

  set activeUtilizationSubPage(subPage: SubPageType.Licenses | SubPageType.Resources | null) {
    this.sessionData.activeUtilizationSubPage = subPage;
    this.saveSessionData();
  }

  get utilizationScheduleType(): ScheduleType {
    return this.sessionData.processScheduleType;
  }

  set utilizationScheduleType(scheduleType: ScheduleType) {
    this.sessionData.processScheduleType = scheduleType;
    this.saveSessionData();
  }

  get utilizationScheduleOrderBy(): string {
    return this.sessionData.processScheduleOrderBy;
  }

  set utilizationScheduleOrderBy(orderBy: string) {
    this.sessionData.processScheduleOrderBy = orderBy;
    this.saveSessionData();
  }

  get selectedUtilizationFilter(): 'all' | 'weekdays' | number {
    return this.sessionData.selectedUtilizationFilter;
  }

  set selectedUtilizationFilter(selectedUtilizationFilter: 'all' | 'weekdays' | number) {
    this.sessionData.selectedUtilizationFilter = selectedUtilizationFilter;
    this.saveSessionData();
  }

  get processesDomainId(): string | null {
    return this.sessionData.processesDomainId;
  }

  set processesDomainId(processesDomainId: string | null) {
    this.sessionData.processesDomainId = processesDomainId;
    this.saveSessionData();
  }

  get processesComparer(): ComparerType {
    return this.sessionData.processesComparer;
  }

  set processesComparer(comparer: ComparerType) {
    this.sessionData.processesComparer = comparer;
    this.saveSessionData();
  }

  get selectedProcessId(): string {
    return this.sessionData.selectedProcessId;
  }

  set selectedProcessId(selectedProcessId: string) {
    if (selectedProcessId !== this.sessionData.selectedProcessId) {
      this.sessionData.selectedProcessId = selectedProcessId;
      this.sessionData.currentVariantId = null;
      this.sessionData.selectedNodeEdgeId = null;
      this.sessionData.openDialogName = null;
      if (!this.sessionData.processMap[this.selectedProcessId]) {
        this.sessionData.processMap[this.selectedProcessId] = {};
      }
      this.saveSessionData();
    }
  }

  get selectedMasterProcessId(): string {
    return this.sessionData.selectedMasterProcessId;
  }

  set selectedMasterProcessId(selectedMasterProcessId: string) {
    if (selectedMasterProcessId && selectedMasterProcessId !== this.sessionData.selectedMasterProcessId) {
      this.sessionData.selectedMasterProcessId = selectedMasterProcessId;
      this.selectedFlameGraphDetailId = null;
      this.saveSessionData();
    }
  }

  get currentVariantId(): string | null {
    return this.sessionData.currentVariantId;
  }

  set currentVariantId(currentVariantId: string | null) {
    this.sessionData.currentVariantId = currentVariantId;
    this.saveSessionData();
  }

  get openDialogName(): string {
    return this.sessionData.openDialogName;
  }

  set openDialogName(openDialogName: string) {
    this.sessionData.openDialogName = openDialogName;
    this.saveSessionData();
  }

  get selectedNodeEdgeId(): string | null {
    return this.sessionData.selectedNodeEdgeId || null;
  }

  set selectedNodeEdgeId(selectedNodeEdgeId: string) {
    this.sessionData.selectedNodeEdgeId = selectedNodeEdgeId;
    this.saveSessionData();
  }

  get position(): { x: number; y: number } {
    return this.sessionData.processMap[this.selectedProcessId]?.position;
  }

  set position(mapPosition: { x: number; y: number }) {
    this.sessionData.processMap[this.selectedProcessId].position = mapPosition;
    this.saveSessionData();
  }

  setPositionForProcess(processId: string, mapPosition: { x: number; y: number }): void {
    if (!this.sessionData.processMap[this.selectedProcessId]) {
      this.sessionData.processMap[this.selectedProcessId] = {};
    }
    if (!this.sessionData.processMap[processId]) {
      this.sessionData.processMap[processId] = {};
    }
    this.sessionData.processMap[processId].position = mapPosition;
    this.saveSessionData();
  }

  get expandedNodeIds(): number[] {
    return this.sessionData.processMap[this.selectedProcessId]?.expandedNodeIds;
  }

  set expandedNodeIds(expandedNodeIds: number[]) {
    this.sessionData.processMap[this.selectedProcessId].expandedNodeIds = expandedNodeIds;
    this.saveSessionData();
  }

  get selectedVariantIds(): string[] {
    return this.sessionData.processMap[this.selectedProcessId]?.selectedVariantIds;
  }

  set selectedVariantIds(selectedVariantIds: string[]) {
    this.sessionData.processMap[this.selectedProcessId].selectedVariantIds = selectedVariantIds;
    this.saveSessionData();
  }

  get mapZoom(): number {
    return this.sessionData.processMap[this.selectedProcessId]?.zoom;
  }

  set mapZoom(zoom: number) {
    this.sessionData.processMap[this.selectedProcessId].zoom = zoom;
    this.saveSessionData();
  }

  get activeActionableInsightStatus(): ActionableInsightStatus | '' {
    return this.sessionData.actionableInsightFilterState;
  }

  set activeActionableInsightStatus(state: ActionableInsightStatus | '') {
    this.sessionData.actionableInsightFilterState = state;
    this.saveSessionData();
  }

  get actionableInsightCategories(): ActionableInsightCategory[] {
    return this.sessionData.actionableInsightSelectedCategories;
  }

  set actionableInsightCategories(categories: ActionableInsightCategory[]) {
    this.sessionData.actionableInsightSelectedCategories = categories;
    this.saveSessionData();
  }

  get masterProcessTableSortDirection(): string {
    return this.sessionData.masterProcessTableSortDirection;
  }

  get hasQuantityMetric(): boolean {
    return this.quantityMetric != null;
  }

  get quantityMetric(): ProcessMapMetric {
    return this.getMetric(this.quantityMetrics);
  }

  set quantityMetric(quantityMetric: ProcessMapMetric) {
    this.setMetric(quantityMetric, this.quantityMetrics);
    // processMapMetricsChanged subject has to differ in each change => thats why string is there
    this.processMapMetricsChanged.next(`Quantity ${quantityMetric}`);
  }

  get hasDurationMetric(): boolean {
    return this.durationMetric != null;
  }

  get durationMetric(): ProcessMapMetric {
    return this.getMetric(this.durationMetrics);
  }

  set durationMetric(durationMetric: ProcessMapMetric) {
    this.setMetric(durationMetric, this.durationMetrics);
    this.processMapMetricsChanged.next(`Average duration ${durationMetric}`);
  }

  get hasPriceMetric(): boolean {
    return this.priceMetric != null;
  }

  get priceMetric(): ProcessMapMetric {
    return this.getMetric(this.priceMetrics);
  }

  set priceMetric(priceMetric: ProcessMapMetric) {
    this.setMetric(priceMetric, this.priceMetrics);
    this.processMapMetricsChanged.next(`Average cost ${priceMetric}`);
  }

  get selectedPlatform(): Platform {
    return this.sessionData.uploadFilesSelectedPlatform;
  }

  set selectedPlatform(selectedPlatform: Platform) {
    this.sessionData.uploadFilesSelectedPlatform = selectedPlatform;
    this.saveSessionData();
  }

  get overviewChartType(): OverviewChartType {
    return this.sessionData.overviewChartType;
  }

  set overviewChartType(type: OverviewChartType) {
    this.sessionData.overviewChartType = type;
    this.saveSessionData();
  }

  // create get set for activeCostAnalysisView
  get activeCostAnalysisView(): string {
    return this.sessionData.activeCostAnalysisView;
  }

  set activeCostAnalysisView(view: string) {
    this.sessionData.activeCostAnalysisView = view;
    this.saveSessionData();
  }

  get selectedFlameGraphDetailId(): string {
    return this.sessionData.selectedFlameGraphDetailId;
  }

  set selectedFlameGraphDetailId(detailId: string) {
    this.sessionData.selectedFlameGraphDetailId = detailId;
    this.saveSessionData();
  }

  get selectedActivityTypeDetailId(): string {
    return this.sessionData.selectedActivityTypeDetailId;
  }

  set selectedActivityTypeDetailId(detailId: string) {
    this.sessionData.selectedActivityTypeDetailId = detailId;
    this.saveSessionData();
  }

  get processPerformanceItemsChartType(): ProcessPerformanceChartType {
    return this.sessionData.processPerformanceItemsChartType;
  }

  set processPerformanceItemsChartType(processPerformanceItemsChartType: ProcessPerformanceChartType) {
    this.sessionData.processPerformanceItemsChartType = processPerformanceItemsChartType;
    this.saveSessionData();
  }

  get processPerformanceSessionsChartType(): ProcessPerformanceChartType {
    return this.sessionData.processPerformanceSessionsChartType;
  }

  set processPerformanceSessionsChartType(processPerformanceSessionsChartType: ProcessPerformanceChartType) {
    this.sessionData.processPerformanceSessionsChartType = processPerformanceSessionsChartType;
    this.saveSessionData();
  }

  get loginType(): LoginType {
    return this.localUserData.loginType;
  }

  set loginType(loginType: LoginType) {
    this.localUserData.loginType = loginType;
    this.saveLocalUserData();
  }

  get microsoftAccountId(): string {
    return this.localUserData.microsoftAccountId;
  }

  set microsoftAccountId(microsoftAccountId: string) {
    this.localUserData.microsoftAccountId = microsoftAccountId;
    this.saveLocalUserData();
  }

  get isMenuExpanded(): boolean {
    return this.localData.isMenuExpanded;
  }

  set isMenuExpanded(expanded: boolean) {
    this.localData.isMenuExpanded = expanded;
    this.saveLocalData();
  }

  get dashboardConfiguration(): DashboardConfiguration {
    return this.localData.dashboardConfiguration;
  }

  set dashboardConfiguration(configuration: DashboardConfiguration) {
    this.localData.dashboardConfiguration = configuration;
    this.saveLocalData();
  }

  constructor() {
    const sessionDataJson = sessionStorage.getItem(this.storageKeys.sessionData);
    this.sessionData = this.parseStorage(sessionDataJson, new SessionStorageData());

    const localDataJson = localStorage.getItem(this.storageKeys.localData);
    this.localData = this.parseStorage<LocalStorageData>(localDataJson, new LocalStorageData());

    const localUserDataJson = localStorage.getItem(this.storageKeys.localUserData);
    this.localUserData = this.parseStorage<LocalUserStorageData>(localUserDataJson, new LocalUserStorageData());

    this.deleteObsoleteKeys();
    this.initialize();
  }

  getDateRange(scope: DateRangeScope): DateRange {
    return this.sessionData.dateRanges[scope];
  }

  setDateRange(scope: DateRangeScope, dateRange: DateRange): void {
    this.sessionData.dateRanges[scope] = dateRange;
    this.saveSessionData();
  }

  private deleteObsoleteKeys(): void {
    sessionStorage.removeItem('app-session-storage-pointee');
    localStorage.removeItem('app-shared-storage-pointee');
  }

  private parseStorage<T>(storage: string, defaultValue: T): T {
    try {
      const parsed = JSON.parse(storage);

      // remove obsolete properties from parsed storage
      Object.keys(parsed).forEach(key => {
        if (defaultValue.hasOwnProperty(key) === false) {
          delete parsed[key];
        }
      });

      return { ...defaultValue, ...parsed } as T;
    } catch {
      return defaultValue;
    }
  }

  private initialize(): void {
    this.processMapMetrics = new Set(this.sessionData.processMapSettingsMetrics);

    if (this.sessionData.dateRanges != null) {
      Object.keys(this.sessionData.dateRanges).forEach(key => {
        const rawDateRange = this.sessionData.dateRanges[key] as any;
        this.sessionData.dateRanges[key] = new DateRange(new Date(rawDateRange._start), new Date(rawDateRange._end));
      });
    }
  }

  private saveSessionData(): void {
    sessionStorage.setItem(this.storageKeys.sessionData, JSON.stringify(this.sessionData));
  }

  private saveLocalData(): void {
    localStorage.setItem(this.storageKeys.localData, JSON.stringify(this.localData));
  }

  private saveLocalUserData(): void {
    localStorage.setItem(this.storageKeys.localUserData, JSON.stringify(this.localUserData));
  }

  clearStorage(clearLocalUserData = false): void {
    this.sessionData = new SessionStorageData();
    this.saveSessionData();

    if (clearLocalUserData) {
      this.localUserData = new LocalUserStorageData();
      this.saveLocalUserData();
    }
  }

  hasMetric(metric: ProcessMapMetric): boolean {
    return this.processMapMetrics.has(metric);
  }

  private getMetric(metrics: ProcessMapMetric[]): ProcessMapMetric {
    return metrics.find(m => this.processMapMetrics.has(m));
  }

  private setMetric(metric: ProcessMapMetric, metrics: ProcessMapMetric[]): void {
    metrics.forEach(m => this.processMapMetrics.delete(m));
    if (metric) {
      this.processMapMetrics.add(metric);
    }
    this.sessionData.processMapSettingsMetrics = [...this.processMapMetrics];
    this.saveSessionData();
  }

  emitProcessMapMetricsChanged(message: string): void {
    this.processMapMetricsChanged.next(message);
  }

  setFilterItem(filterName: string, value: any): void {
    (this.sessionData.filterStorage as Record<string, any>)[filterName] = JSON.stringify(value);
    this.saveSessionData();
  }

  getFilterItem(filterName: string): any {
    const filterValue = this.sessionData.filterStorage[filterName];
    return typeof filterValue === 'string' ? JSON.parse(filterValue) : null;
  }
}
